import React, { Component } from "react";
import Loader from 'react-loader-spinner';
import GetEventCategories from '../../GlobalServices/GetEventCategories.js';
import SubscriptionForm from './SubscriptionForm.js'
import '../../../sass/subscriptionform.scss';
import  AuthContext  from " ../../../src/Auth";
import { Link } from 'react-router-dom';

class NewSubscription extends Component {
    constructor(props) {
        super(props);

        if(this.props.location.state == null){
            this.logOut(); 
        }

        if(AuthContext.loginSubscriberId==null){
            AuthContext.loginSubscriberId =(this.props.location.state.loginSubscriberId===null) ? this.props.state.loginSubscriberId :this.props.location.state.loginSubscriberId;
        }
        
        this.state = {
            eventCategories: [],
            orgId: (this.props.location.state.orgId===null) ? this.props.state.orgId :this.props.location.state.orgId,
            subscriptionId: (this.props.location.state.subscriptionId ===null) ? this.props.state.subscriptionId:this.props.location.state.subscriptionId,          
            loginSubscriberId:AuthContext.loginSubscriberId,
            isNewSubscription:true,
            loaded: false
        };

    }

    async componentDidMount() {
        var eventCategoriesObject = await GetEventCategories();
        if (eventCategoriesObject != null) {
            this.setState({
                eventCategories: eventCategoriesObject,
                eventCategoryId: eventCategoriesObject[0].eventCategoryID,
                events: eventCategoriesObject[0].events,
                approverMail:eventCategoriesObject[0].approverEmailAddress,
                loaded: true
            });
        }
    }
    logOut() {
        AuthContext.logOut();
        AuthContext.clearCache();
    }
    render() {
        const { orgId, loginSubscriberId,isNewSubscription, subscriptionId, eventCategories,approverMail, eventCategoryId, events, loaded } = this.state;
        const logOut =
        <button type="button" className="btn btn-link" onClick={this.logOut}>Log Out </button>
        const header = <div className="Header">
            <div className="Header-banner">
                <div className="Header-corner_img"></div></div>
            <div className="Header-title">
                Event Bus Broker
            </div>
            <p className="Header-welcome">
                Hello {AuthContext.getCachedUser().profile.name}.
            </p>
           
                
            <div className="Header-about">
            <Link to={{
                    pathname: '/About',
                    state: {    orgId: 1 }}}
                target="_About" rel='noopener noreferrer'>
               About
            </Link>&nbsp;&nbsp;&nbsp;&nbsp;  <br></br><br></br><br></br> <br></br>{logOut}
            
                    <div className="Header-Homepage">
          
          <Link to={{
              pathname:  '/Subscriptions',              
             state: { orgId: orgId,
                loginSubscriberId:this.state.loginSubscriberId 
             }
          }}>Home</Link>
      
          </div>
         

                  </div>
        </div>
        if (loaded) {
            return (
                <div>
                    <SubscriptionForm orgId={orgId} subscriptionId={subscriptionId} loginSubscriberId={loginSubscriberId} 
                   isNewSubscription={isNewSubscription} eventCategories={eventCategories}  eventCategoryId={eventCategoryId} events={events} approverMail={approverMail}/>
                </div>);
        } else {
            return (<div>{header}
                <div className="Header-loading">
                <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} timeout={3000}/> 
                </div>
            </div>);
        }
    }
}

export default NewSubscription;