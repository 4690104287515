import React,{Component} from "react";
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import GetSubscription from '../Services/GetSubscription.js';
import GetEventCategory from '../Services/GetEventCategory.js';
import '../../../sass/header.scss';
import '../../../sass/eventpage.scss';

class EventSchema extends Component{
    constructor(props) {
        super(props);
        this.state={
            orgId:this.props.location.state.orgId,
            subscriptionId: this.props.location.state.subscriptionId,
            loginSubscriberId: this.props.location.state.loginSubscriberId,            
            eventCategoryId: this.props.location.eventCategoryId,   
            eventXmlSchema:null,
            eventJsonPayload:null,
            eventXmlPayload:null,        
            loaded: false
        };               
    }

    async componentDidMount() {
        var subscriptionId = this.state.subscriptionId;        
        if (subscriptionId != null) {
            const subscription = await GetSubscription(subscriptionId);
            const eventCategory = await GetEventCategory(subscription.eventCategoryID);                
            this.setState({
                loaded:true,
                eventXmlSchema: eventCategory.eventXmlSchema,
                eventXmlPayload: eventCategory.eventXmlPayload,
                eventJsonPayload:eventCategory.eventJsonPayload               
            });
            
        }  
    }
    // logOut() {
    //     AuthContext.logOut();
    //     AuthContext.clearCache();
    // }
    render() {
        const reactStringReplace = require('react-string-replace')
        const {eventXmlSchema,eventXmlPayload,eventJsonPayload,loaded}=this.state;
        const xmldata=reactStringReplace(reactStringReplace(eventXmlSchema, /(1#)/g, () => ""),/(0#)/g, () => "")
        const jsondata=reactStringReplace(reactStringReplace(eventJsonPayload, /(1#)/g, () => ""),/(0#)/g, () => "")
         
       
       
       const headerlink=
       <div className="EventSchema-headerlink">
       <Link to={{
        pathname: '/Subscriptions',
        state: {    orgId: this.state.orgId,
                    subscriptionId: this.state.subscriptionId,
                    loginSubscriberId:this.state.loginSubscriberId    }
        }}>Subscriptions </Link> {">"} Event Examples</div>;

        

        const eventxmlheader=
        <div className="EventSchema-Eventsdiv"> 
            <h1 className="EventSchema-titleA">Events Schema</h1>           
            <h2 className="EventSchema-titleB">Event Example</h2>           
        </div>;
        
        if(loaded)
        {
        return (                         
        <div> {headerlink}{eventxmlheader} 
           <div className="EventSchema-PanelA">
           <pre className="EventSchema-Paneltext">{eventXmlPayload}</pre>
            </div>
            <div>
            <div className="EventSchema-PanelB">
            <div className="EventSchema-innerTitle">XMLPayload</div>
                <pre className="EventSchema-Paneltext">{xmldata}</pre>                                
            </div>            
            <div className="EventSchema-PanelC">
                <div className="EventSchema-innerTitle"> JsonPayload</div>
                <pre className="EventSchema-Paneltext">{jsondata}</pre>                                
            </div>   
            </div>         
        </div>  
        )
        }
        else {
            return ( 
                <div className="Header-loading"> 
                <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} timeout={3000}/>        
                </div>
            );
        }
    }
}
export default withRouter(EventSchema);
