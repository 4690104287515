import React, { Component } from "react";
import '../../sass/header.scss'
import  AuthContext  from " ../../../src/Auth";

class NotFoundError extends Component{
constructor(props) {
    super(props);
    this.state = {
        orgId: 1
    };}

    render()
    {
    return(
    
         <div className="Header">
        <div className="Header-banner">
            <div className="Header-corner_img"></div>
        </div>
        <div className="Header-title">
            Event Bus Broker
            </div>
        <p className="Header-welcome">
            Hello {AuthContext.getCachedUser().profile.name}.
            </p>
        <div className="Header-errorMessage">
         Page Not Found
                 </div>
                 </div>
                 
                 
    
    );
    }
}
    

export default NotFoundError;