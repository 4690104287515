import React, { Component } from "react";
import { Form, FormGroup, Label, Input, Col, Button } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import GetOrganizations from "./../../OrganizationsManagement/Services/GetOrganizations.js";
import UpdateSubscriber from "../Services/UpdateSubscriber.js";
import DeleteSubscriber from "../Services/DeleteSubscriber.js";
import AuthContext from "../../../Auth.js";
import "../../../sass/header.scss";
import "../../../sass/adminpage.scss";

class AdminManageSubscriber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriber: this.props.location.state.subscriber,
      subscriberId: this.props.location.state.subscriber.subscriberID,
      subscriberName: this.props.location.state.subscriber.name,
      subscriberOrgId: this.props.location.state.subscriber.orgID,
      subscriberOrgName: this.props.location.state.subscriber.orgName,
      loginSubscriberId: AuthContext.loginSubscriberId,
      loginSubscriberOrgId: this.props.location.state.loginSubscriberOrgId,
      organizations: [],
    };

    this.handleBackToAdminPage = this.handleBackToAdminPage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleChange = (o) => {
    this.setState({ subscriberOrgId: o.target.value });
  };

  handleCancel = (s) => {
    s.preventDefault();
    this.props.history.push({
      pathname: "/AdminPage",
      state: {
        orgId: this.state.loginSubscriberOrgId,
        loginSubscriberId: this.state.loginSubscriberId,
      },
    });
  };

  handleDelete = async () => {
    var subscriberId = this.props.location.state.subscriber.subscriberID;
    const loginSubscriberOrgId = this.state.loginSubscriberOrgId;
    this.setState({ isLoading: true });
    await DeleteSubscriber(subscriberId);
    this.setState({ isLoading: false });
    this.props.history.push({
      pathname: "/AdminPage",
      state: {
        orgId: loginSubscriberOrgId,
        loginSubscriberId: this.state.loginSubscriberId,
      },
    });
  };

  handleSave = async () => {
    var subscriber = this.props.location.state.subscriber;
    subscriber.orgID = this.state.subscriberOrgId;
    var subscriberId =
      this.props.subscriberId !== null &&
      typeof this.props.subscriberId !== "undefined"
        ? this.props.subscriberId
        : this.state.subscriberId;
    if (subscriberId !== null && typeof subscriberId !== "undefined") {
      var applyXSL = 0;

      this.setState({
        flagSubscriptionCreated: 0,
        isLoading: true,
      });

      var result = await UpdateSubscriber(subscriberId, subscriber, applyXSL);
      if (
        result.data != null &&
        result.data.subscriberID != null &&
        result.data.subscriberID > 0
      ) {
        alert("Subscriber Organization Has Been Updated Successfully");
        this.props.history.push({
          pathname: "/AdminPage",
          state: {
            orgId: this.state.loginSubscriberOrgId,
            loginSubscriberId: this.state.loginSubscriberId,
          },
        });
      }
    }
  };

  handleBackToAdminPage = (s) => {
    s.preventDefault();
    this.props.history.push({
      pathname: "/AdminPage",
    });
  };

  async componentDidMount() {
    const organizationsObject = await GetOrganizations();
    if (organizationsObject != null) {
      this.setState({ organizations: organizationsObject });
    }
  }

  logOut() {
    AuthContext.logOut();
    AuthContext.clearCache();
  }

  render() {
    const { loginSubscriberOrgId, subscriberOrgId } = this.state;

    if (loginSubscriberOrgId !== "1") {
      return <Redirect to="/" />;
    }

    const backToAdminPageButton = (
      <button className="btn btn-link" onClick={this.handleBackToAdminPage}>
        Back to Admin Page
      </button>
    );

    const logOut = (
      <button type="button" className="btn btn-link" onClick={this.logOut}>
        Log Out{" "}
      </button>
    );

    const organizationsDropdown = (
      <Input type="select" value={subscriberOrgId} onChange={this.handleChange}>
        {this.state.organizations.map((org) => (
          <option
            className="Homepage-org-text"
            key={org.orgID}
            value={org.orgID}
          >
            {org.orgID} - {org.description}
          </option>
        ))}
      </Input>
    );

    const delButton = (
      <Button
        className="AdminManageForm-btn AdminManageForm-delete-button"
        onClick={(e) => {
          if (
            window.confirm(
              "Are you sure you wish to delete this Subscriber? It CAN'T be reversed once you click OK!"
            )
          )
            this.handleDelete(e);
        }}
      >
        Delete
      </Button>
    );

    const cancelButton = (
      <Button
        className="AdminManageForm-btn AdminManageForm-cancel-button"
        onClick={this.handleCancel}
      >
        Cancel
      </Button>
    );
    const saveButton = (
      <Button
        className="AdminManageForm-btn AdminManageForm-save-button"
        onClick={this.handleSave}
      >
        Save
      </Button>
    );

    const subscriptionForm = (
      <Form>
        <FormGroup>
          <div className="function">
            <Label>
              Subscriber's Organization<font color="red">*</font>
            </Label>
          </div>
          <Col sm={13}>{organizationsDropdown}</Col>
        </FormGroup>

        <FormGroup>
          <div className="AdminManageForm-button-group">
            <Col>
              {saveButton}
              {cancelButton}
              {delButton}
            </Col>
          </div>
        </FormGroup>
      </Form>
    );

    const adminHeader = (
      <div className="Header">
        <div className="Header-banner">
          <div className="Header-corner_img"></div>
        </div>
        <div className="Header-title">Event Bus Broker</div>
        <p className="Header-welcome">
          Hello {AuthContext.getCachedUser().profile.name}.
        </p>
        <div className="Header-adminabout">
          <Link
            to={{
              pathname: "/About",
              state: { orgId: 1 },
            }}
            target="_About"
            rel="noopener noreferrer"
          >
            About
          </Link>
          <br></br>
          <br></br>
          <br></br>
          {backToAdminPageButton}
          {logOut}
          <br></br>
        </div>
      </div>
    );

    const formComponents = (
      <div className="AdminManageForm">
        {
          <div className="AdminManageForm-title">
            {this.state.subscriberName}
          </div>
        }
        {<div className="AdminManageForm-editmaindiv">{subscriptionForm}</div>}
      </div>
    );

    return (
      <React.Fragment>
        <div>
          <div>{adminHeader}</div>
          {formComponents}
        </div>
      </React.Fragment>
    );
  }
}

export default AdminManageSubscriber;
