import React, { Component } from "react";
import { Link, withRouter } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import DeliveredEvents from './DeliveredEvents.js';
import DisplayReports from './DisplayReports.js';
import '../../sass/header.scss';
import '../../sass/reports.scss';
import ValidateDate from '../GlobalServices/ValidateDate.js';
import  AuthContext  from '../../Auth.js';
import '../../sass/subscriptionform.scss';


class ManageReport extends Component {
    constructor(props) {
        super(props);        
        let currentDate = new Date();
        let defaultEndDate= new Date();
        defaultEndDate.setHours(defaultEndDate.getHours()+2,defaultEndDate.getMinutes(),0,0);
        this.state = { 
            loginSubscriberId: AuthContext.loginSubscriberId,
            orgId:this.props.location.state.orgId,
            subscriptionId: this.props.location.state.subscriptionId,            
            subscriptionName:this.props.subscriptionName,            
            submitted: false,
            startDT: currentDate,
            endDT: defaultEndDate,           
            date:null,            
            selectedReport:'events',
            labelerrormessage:null,                      
            activeTab: '1'
            
        };
        this.handleChangeCategory = this.handleChangeCategory.bind(this);
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);        
        this.handleSubmit = this.handleSubmit.bind(this);   
        this.toggle = this.toggle.bind(this);
    }
    

    handleChangeCategory = e => {              
        this.setState({
            selectedReport: e.target.value,          
            submitted: false
        })       
    }

    handleChangeStart = (date) => {
        this.setState({
            startDT: date,            
            submitted: false
        });        
    }

    handleChangeEnd = (date) => {
        this.setState({
            endDT: date,
            submitted: false
        });
    }
    


    handleSubmit = o => {
      o.preventDefault();
      this.handleChangeStart = this.handleChangeStart.bind(this);
      this.handleChangeEnd = this.handleChangeEnd.bind(this);
      this.handleChangeCategory = this.handleChangeCategory.bind(this);
      if((this.state.startDT==='') && (this.state.selectedReport ==='events'))
      {
            this.setState({
                labelerrormessage:'Please select Start Date',
                submitted: false
            });
        }
        else if((this.state.endDT==='') && (this.state.selectedReport ==='events'))
        {
            this.setState({
                labelerrormessage:'Please select End Date',
                submitted: false
            });
        }
                
        if(ValidateDate(this.state.startDT,this.state.endDT)!==true) {    
                this.setState({
                    labelerrormessage:'Start Date is greater than End Date',
                    submitted: false
                });             
                }   
        
        
                
            else
            {
            this.setState({
                labelerrormessage:null,
                submitted: true
            },);
    

    }
    }

    toggle(tab) {       
          this.setState({
            activeTab: tab
          });        
      }

    
    render() {
        const {subscriptionId,subscriptionName,orgId } = this.state;

        let DisplayReportsPage= <DisplayReports subscriptionId={subscriptionId}  subscriptionName={subscriptionName}
        selectedReport='subscriptions'  orgId={orgId}></DisplayReports>;

        let DeliveredEventsPage=  <DeliveredEvents subscriptionId={subscriptionId} orgId={orgId} key="4" /> ;

         const headerlink=
          <div className="Reports-headerlink">
          <Link to={{
           pathname: '/Subscriptions',
           state: {    orgId: this.state.orgId,
                       subscriptionId: this.state.subscriptionId,
                       loginSubscriberId:this.state.loginSubscriberId }
           }}>Subscriptions </Link> {">"} Reports</div>;

          
        
            return (<div>                 
            {headerlink}

            <div tabIndex='-1' className="Reports">
            <div className="Reports-title">Reports</div>


              


        <Row>
            <Col sm="2">
              <Nav tabs vertical pills>
                <NavItem>
                  <NavLink                     
                     className={(this.state.activeTab === '1')  ? 'linkselect':'linkunselect'}
                    onClick={() => {
                      this.toggle('1');
                    }}
                  >
                 Delivered Events
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={(this.state.activeTab === '2')  ? 'linkselect':'linkunselect'}
                    onClick={() => {
                      this.toggle('2');
                    }}
                  >
                    Subscription Details
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col sm="10">
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                {DeliveredEventsPage}
                </TabPane>
                <TabPane tabId="2">
                {DisplayReportsPage}
                </TabPane>
              </TabContent>
            </Col>
          </Row>
 
                </div>
         
            </div>);       
    }
}

export default withRouter(ManageReport);
