import axios from "axios";
import configData from "./../../../config.json";

const baseUri = configData.APIURL;
const apikey = configData.APIKEY;



export default async (subscriptionID,subscriptionName) => {    
    try {                   
        const result = await axios({
            method: "get",
            headers: { 'Ocp-Apim-Subscription-Key': apikey,
                       'Access-Control-Allow-Origin' : '*' },
            url: baseUri + `/subscriptions/${subscriptionID}/sinks`
        });            
        var SinkDataObject= result.data.sinks;               ;
        for (var sinkDataIndex = 0; sinkDataIndex < SinkDataObject.length; sinkDataIndex++) {     
            var sinkData = SinkDataObject[sinkDataIndex];
            sinkData.row = sinkDataIndex + 1;
            sinkData.subscriptionName = subscriptionName;                                               
            }              
        return SinkDataObject;  
    } catch (error) {
        return null;
    }
}