import React,{Component } from "react";
import {  Input } from 'reactstrap';
import { withRouter } from 'react-router';
import Loader from 'react-loader-spinner';
import { TreeTable, TreeState } from 'cp-react-tree-table';
import CheckInputChanges from '../SinksComponents/Services/CheckInputChanges.js';
import UpdateSubscription from "../Services/UpdateSubscription.js";
import GetSelectedFields from '../Services/GetSelectedFields.js';
import '../../../sass/SelectFields.scss';
import '../../../sass/currentsubscriptions.scss';
import '../../../sass/header.scss';
import { Link } from 'react-router-dom';
import  AuthContext  from " ../../../src/Auth";


class SelectFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
        fieldArray:[],
        criteria:  [
            {id:1, value: '', name: '' },
            {id:2, value: '=', name: '=' },    
            {id:3, value: '>', name: '>' },
            {id:4, value: '<', name: '<' },
            {id:5, value: 'in', name: 'in' },
            {id:6, value: 'contains', name: 'contains' }
        ],

        subscriptionId: this.props.location.state.subscriptionId,        
        loginSubscriberId:AuthContext.loginSubscriberId,
        eventCategoryId: this.props.eventCategoryID,
        orgId:this.props.location.state.orgId,
        changeValue:null,                
        hasData:false,
        loaded:false,
        isUpdated:false,
        isChanged:false
    }
   
   this.resetControls = this.resetControls.bind(this);  
   this.handlesave = this.handlesave.bind(this);  
  }

  treeTableRef = React.createRef();

  generateSchema=async() =>
  {
    var eventSchema = await GetSelectedFields(this.props.location.state.subscriptionId,this.state.eventCategoryId);   
    if(eventSchema !=null) 
    {
      this.setState({
          fieldArray:this.state.fieldArray.concat(eventSchema),
          hasData:true
      });
    }
    this.getSelectedSchema();
  }


    async componentDidMount() { 
      this.generateSchema();              
  }

  getSelectedSchema = async () => {    
      
      if (this.state.hasData) {
          this.setState({
              loaded:true,                                      
              treeValue: TreeState.create(this.state.fieldArray)           ,
              existingTreeValue: TreeState.create(this.state.fieldArray) 
          });
      }
      this.handleOnExpandAll();
  }


resetControls = async e => { 
  if(this.state.changeValue!=null)
  { 
   for ( var i = 0; i < this.state.changeValue.data.length; i++) {     
    this.state.changeValue.data[i].data.isChecked=true;
    this.state.changeValue.data[i].data.value='';
    this.state.changeValue.data[i].data.condition='';
   }      
      this.setState({
      treeValue:this.state.changeValue});
  }
}
 
 

    handleOnExpandAll = () => {  
      this.setState((state) => {
        return {
          treeValue: TreeState.expandAll(state.treeValue),
        };
      });
    }

    handleOnCollapseAll = () => {  
      this.setState((state) => {
        return {
          treeValue: TreeState.collapseAll(state.treeValue)
        };
      });
    }


    IsCorrectString = function(value){ 
            var stararray = value.split(' ');
            var bResult =true;
            const re = /^[0-9a-zA-Z]+$/;
            for (var i=0; i<stararray.length;i++)
            {
                var str=stararray[i];
                for (var j = 0; j < str.length; j++) {                    
                 
                    if (! (re.exec(str.charAt(j)) || str.charAt(j) === "'" || str.charAt(j) === ","))
                    {
                        bResult= false;
                        break;
                    }
                  }
            } 
            return bResult; 
        }
      
 
  handlesave = async () => {
    var lmsg=document.getElementById("lblMessages");
    lmsg.className="hide";
    //var data=this.state.fieldArray;
    var FilterCondition="" ,SelectedFields="",allSelectedFields=true;
    var isvalid=true;     


    if(this.state.isChanged)
    {
    for ( var i = 0; i < this.state.changeValue.data.length; i++) {
      if (this.state.changeValue.data[i].data.isChecked) 
        {
            SelectedFields += this.state.changeValue.data[i].data.fieldName + ",";
        }
        else {
            allSelectedFields=false;
        }

        if(this.state.changeValue.data[i].data.value!=null && this.state.changeValue.data[i].data.condition !=null && this.state.changeValue.data[i].data.condition!=="")// && data[i].value!==""  )
        {
            if(this.IsCorrectString(this.state.changeValue.data[i].data.value))
            {
               
            FilterCondition += this.state.changeValue.data[i].data.fieldName + "#" + this.state.changeValue.data[i].data.condition + "#" + this.state.changeValue.data[i].data.value + "|";
            }
            else    
            {
            lmsg.className="error";
            lmsg.innerHTML = "Only single-quote and commas are accepted besides numbers and letters for the Conditions fields.";
            isvalid=false;
            break;
           
            }
        }
        if(!isvalid)
        { return;}

    }    

    if(allSelectedFields)
    {
        SelectedFields = "ALL";
    }
    else{        
       SelectedFields = SelectedFields.substring(0, SelectedFields.length - 1);
        }

        if (FilterCondition !== "") { FilterCondition = FilterCondition.substring(0, FilterCondition.length - 1);
        }
    var propsSubscription = [this.state.subscriptionId, this.state.loginSubscriberId,SelectedFields];       
        const subscription= {
            SubscriptionID: this.state.subscriptionId,
            SubscriberID:  this.state.loginSubscriberId,
            SelectedFields :SelectedFields,
            FilterCondition : FilterCondition
        }
       
        
        var applyXSL=1;


        if (CheckInputChanges(propsSubscription) === "ERROR" || !CheckInputChanges(propsSubscription)) 
        {            
            lmsg.className="error";
            lmsg.innerHTML = "Bad Subscription Request.";
            return false;
        } else 
          {
            if (this.state.subscriptionId != null)
            {
              this.setState({ isUpdated:true});
               await UpdateSubscription(this.state.subscriptionId, subscription,applyXSL);               
               this.setState({ isUpdated:false});
               lmsg.className="updmsg";
               lmsg.innerHTML = "Subscription Events Updated successfully.";                               
            } 
          }
        }
  }

    handleclose= s => {
        s.preventDefault();
        this.props.history.push({
            pathname: "/EditSubscription",
            state: {
                orgId: this.state.orgId,
                subscriptionId: this.state.subscriptionId
            }
        });
       
    }
 
    
      renderIndexCell = (row) => {
        return (
          <div style={{ paddingLeft: (row.metadata.depth * 15) + 'px'}}
            className={row.metadata.hasChildren ? 'with-children' : 'without-children'}>
            
            {(row.metadata.hasChildren)
              ? (
                  <button className={`toggle-button ${row.$state.isExpanded ? 'expanded' : ''}`}
                  onClick={row.toggleChildren}></button>                  
                )                
              : ''
            }            
            <span>{row.data.displayFieldName}</span>
          </div>
        );
      }
 
     


      renderSelectCell = (row) => {
        return (
            <Input type="checkbox" id={'chk_'+row.data.fieldName} className="chekboxcell"                            
            checked= {(row.data.isChecked ===null) ?false :row.data.isChecked} 
            onChange={(event) => {
              row.updateData({
                ...row.data,
                isChecked: (row.data.isChecked===true)?false:true,
              });
            }}
             />         
          );
      }
    
      renderDropdownCell = (row) => {
        var criteria = this.state.criteria;
        return (
            <select id={'ddl_'+ row.data.fieldName}  value={(row.data.condition ===null) ?"" :row.data.condition}
            className={row.data.isEditable ? 'show' : 'hide'} 
            onChange={(event) => {
              row.updateData({
                ...row.data,
                condition: event.target.value                
              });
            }}
             >
                 {criteria.map((cri) => (
           <option className="Homepage-org-text" id={cri.id} value={cri.value}>{cri.name}</option>
       ))}
      
        </select>
        );
      }

     
    
      renderEditableTextCell = (row) => {
        return (
          <input type="text" value={row.data.value} className={row.data.isEditable ? 'show' : 'hide'}           
            onChange={(event) => {
              row.updateData({
                ...row.data,
                value: event.target.value,
              });
            } }
          />            
        );
      }
    
      handleOnChange = (newValue) => {
         var displayName;
         var checkedValue;
         var fieldName;         
         var childCheckedValue;
         var parentTagName;   

        this.setState({ treeValue: newValue,
          changeValue:newValue,
          isChanged:true });

          
        if(newValue!=null)
        {   
          for ( var i = 0; i < newValue.data.length; i++) {               
       
            if ((newValue.data[i].data.isChecked!==this.state.existingTreeValue.data[i].data.isChecked) )
            {
              if(newValue.data[i].metadata.hasChildren)  
              {                          
                displayName=newValue.data[i].data.displayFieldName;
                checkedValue=newValue.data[i].data.isChecked;                            
              }
              else
              {
                  fieldName=newValue.data[i].data.fieldName;  
                  childCheckedValue=newValue.data[i].data.isChecked;                              
                

                if(childCheckedValue===true)
                  parentTagName=fieldName.split('.');
              }
            }

           
            if ((newValue.data[i].data.condition!==this.state.existingTreeValue.data[i].data.condition) )
            { 
              if(newValue.data[i].data.condition!=='')             
                  newValue.data[i].data.isChecked=true;
              else              
                  newValue.data[i].data.isChecked=false;
            }

            if ((newValue.data[i].data.value!==this.state.existingTreeValue.data[i].data.value) )
            { 
              if(newValue.data[i].data.value!=='')             
                  newValue.data[i].data.isChecked=true;
              else              
                  newValue.data[i].data.isChecked=false;
            }

          }
          
          if(displayName!=null)
          { 
            for ( var incrementValue = 0; incrementValue < newValue.data.length; incrementValue++) {           
                if (((newValue.data[incrementValue].data.fieldName).search(displayName) > -1)  )
                {                  
                  newValue.data[incrementValue].data.isChecked=checkedValue;
                }         
            }
          }

          if(parentTagName!=null)
          { 
            for ( var incrementparentValue = 0; incrementparentValue < newValue.data.length; incrementparentValue++) {                 
                for(var j=0;j<parentTagName.length-1;j++)
                {
                  if ((newValue.data[incrementparentValue].data.displayFieldName)===parentTagName[j])
                  {             
                    newValue.data[incrementparentValue].data.isChecked=childCheckedValue;
                  } 
                }    
            }
          }
        }    
        this.setState({existingTreeValue:newValue});
      }

  render()
  {
    const { treeValue } = this.state;   
    let loaderbutton,saveButton,clearButton,collapseButton,expandButton;
   
    
    if(this.state.isUpdated)
        {
            loaderbutton=<Loader type="ThreeDots" color="#00BFFF" height={50} width={50}  timeout={3000}/> 
        } 

    const headerlink=
              <div className="EventSchema-headerlink">
              <Link to={{
                pathname: '/Subscriptions',
                state: {    orgId: this.state.orgId,
                            subscriptionId: this.state.subscriptionId,
                            loginSubscriberId:this.state.loginSubscriberId }
                }}>Subscriptions </Link> {">"}  Select Fields</div>;
   
         
    
    saveButton = <button className="Selectedevents-save-button" 
                  disabled={!(this.state.isChanged)}  onClick={this.handlesave}>Save</button>  

    clearButton =<button className="Selectedevents-cancel-button" 
                  disabled={!(this.state.isChanged)}onClick={this.resetControls}>Clear</button>
    
   
    collapseButton = <button className="Selectedevents-collapse-button" onClick={this.handleOnCollapseAll}>Collapse All</button>
    
    expandButton = <button className="Selectedevents-expand-button" onClick={this.handleOnExpandAll}>Expand All</button>

 
     
    if(this.state.loaded)
    {
      return ( 
        

        <React.Fragment>
        <div>{headerlink}</div>
        <div className="selectEvents-title" >Schema fields </div>
        <div className="Page-Content">        
        <div ><br/>  
        <div>
        
       
       <div className="rule">&nbsp;&nbsp;<b> Rules:</b></div>
        <div className="Page-middlecontent">
        <div>
            <ol type="1">
              <li>Select the fields that must be included in the event messages. Fields unchecked will not be included in the event payload.</li>
              <li>Criteria for string values must be enclosed in single quotes, and comma-separated when using "In" operand.
                  Examples: <b>='SEA'</b>  or  <b>in 'AS','QX'</b></li>
                <li> To remove existing Conditions, select the non-value option from the drop-down.</li>

            </ol>
        </div>
       </div>  
       
       <div className="hideButtons">  
        
       {saveButton} {clearButton}   
              
           {expandButton} {collapseButton} </div>

       
       <div className="loaderstyle"> { loaderbutton } </div>

  
         
          
          </div>
       </div>

       <div><label id="lblMessages" className="hide" > </label>
               
      <div>          
        

      <TreeTable  height="660" headerHeight="32" className="cp_tree-table"
          value={treeValue} onChange={this.handleOnChange}
          ref={this.treeTableRef}>

          <TreeTable.Column  basis="130px" grow="0"
            renderCell={this.renderSelectCell}
            renderHeaderCell={() => <span>SELECT</span>}/> 

          <TreeTable.Column  basis="500px" grow="0"
            renderCell={this.renderIndexCell}
            renderHeaderCell={() => <span>FIELD NAME</span>}/>

          <TreeTable.Column  basis="250px" grow="0"
            renderCell={this.renderDropdownCell}
            renderHeaderCell={() => <span>CONDITION</span>}/>

          <TreeTable.Column  basis="380px" grow="0"
            renderCell={this.renderEditableTextCell}
            renderHeaderCell={() => <span>VALUE</span>}/>      
        </TreeTable>     
     </div>

     </div>
     </div>
     </React.Fragment>
  )
      }
      else {
        return (<div>
          <div className="Header-loading">
               <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} timeout={3000}/>     
           </div>
       </div>);
    }
    }
}

export default withRouter(SelectFields);







