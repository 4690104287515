import axios from "axios";
import configData from "./../../../config.json";

const baseUri = configData.APIURL;
const apikey = configData.APIKEY;


export default async (subscriptionId) => {
    try {        
        console.log('baseUri: '+baseUri);      
        console.log('apikey: '+apikey);
        const result = await axios({
           method: "get",
            headers: { 'Ocp-Apim-Subscription-Key': apikey,
                       'Access-Control-Allow-Origin' : '*' },
            url: baseUri + `/subscriptions/${subscriptionId}/auditEvents`
            
        });        
        return result.data.audits;
    } catch(error) {
        return null;
    }
}