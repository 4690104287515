import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";
import "../../sass/adminpage.scss";

class AllSubscriptionsTable extends Component {
  colFormatterManage = (cell, row) => {
    return (
      <Link
        to={{
          pathname: "/AdminManageSubscription",
          state: {
            subscription: row,
            loginSubscriberId: this.props.loginSubscriberId,
            loginSubscriberOrgId: this.props.orgId
          },
        }}
      >
        {cell}
      </Link>
    );
  };

  render() {
    const pagingoptions = {
      page: 1,
      sizePerPage: 10, // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      hideSizePerPage: false, // You can hide the dropdown for sizePerPage
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, //> Hide the going to First and Last page button
      paginationPosition: "bottom",
      sizePerPageList: [
        {
          text: "Show 5 per page",
          value: 5,
        },
        {
          text: "Show 10 per page",
          value: 10,
        },
        {
          text: "Show All",
          value: this.props.data === undefined ? 0 : this.props.data.length,
        },
      ], // you can change the dropdown list for size per page
    };

    return (
      <div className="AdminAllSubscriptions">
        <div className="AdminAllSubscriptions-table">
          <BootstrapTable
            data={this.props.data}
            pagination
            options={pagingoptions}
          >
            <TableHeaderColumn
              isKey
              dataField="subscriptionName"
              filter={{
                type: "TextFilter",
                delay: 500,
                style: { height: "25px" },
                placeholder: "Enter Subscription Name",
              }}
              dataFormat={this.colFormatterManage}
              width={"24%"}
            >
              SUBSCRIPTION NAME
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="orgID"
              width={"10%"}
              filter={{
                type: "TextFilter",
                delay: 500,
                style: { height: "25px" },
                placeholder: "Enter ID",
              }}
            >
              ORG ID
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="orgName"
              width={"16%"}
              filter={{
                type: "TextFilter",
                delay: 500,
                style: { height: "25px" },
                placeholder: "Enter Org Name",
              }}
            >
              ORG NAME
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="subscriberName"
              width={"15%"}
              filter={{
                type: "TextFilter",
                delay: 500,
                style: { height: "25px" },
                placeholder: "Enter Name",
              }}
            >
              SUBSCRIBER NAME
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="contactEmail"
              width={"24%"}
            >
              SUBSCRIBER EMAIL
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    );
  }
}

export default AllSubscriptionsTable;
