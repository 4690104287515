import configData from "./config.json";

// src/AdalConfig.js
export default {
  clientId:  configData.CLIENT_ID,//'c2ad0d72-175a-4bf4-9bf0-4e637f2f7f73',// 'ENTER THE APPLICATION ID OF THE REGISTERED WEB APP ON AZURE',
  endpoints: {
    api: configData.CLIENT_ID//"c2ad0d72-175a-4bf4-9bf0-4e637f2f7f73"//"ENTER THE APPLICATION ID OF THE REGISTERED API APP ON AZURE" // Necessary for CORS requests, for more info see https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/CORS-usage
  },
  // 'tenant' is the Azure AD instance.
  REACT_APP_TENANT_ID: configData.CLIENT_ID,//'0f44c5d4-42b0-45c2-bf55-d0fea8430d33',//'ENTER YOUR TENANT ID',
  postLogoutRedirectUri: window.location.origin,
  redirectUri: configData.REDIRECT_URL,//`https://flyadmin.test.alaskasworld.com`,
  // 'cacheLocation' is set to 'sessionStorage' by default (see https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/Config-authentication-context#configurable-options.
  // We change it to'localStorage' because 'sessionStorage' does not work when our app is served on 'localhost' in development.
  cacheLocation: 'localStorage'
  
  }