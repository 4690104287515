import React, { Component } from "react";
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import Loader from 'react-loader-spinner';
import GetEventCategories from '../../GlobalServices/GetEventCategories.js';
import SelectReports from '../../ReportsManagement/ManageReport.js';
import ViewSubscriptionChanges from '../../SubscriptionsManagement/CurrentSubscriptions/ViewSubscriptionChanges.js';
import SubscriptionForm from './SubscriptionForm.js';
import EventsExample from './EventSchema.js';
import SelectFields from './SelectFields.js'
import '../../../sass/subscriptionform.scss';
import  AuthContext  from " ../../../src/Auth";
import { Link } from 'react-router-dom';


class ManageSubscription extends Component {
    constructor(props) {
        super(props);

        if(this.props.location.state == null){
            this.logOut(); 
        }

        if(AuthContext.loginSubscriberId==null){
            AuthContext.loginSubscriberId =(this.props.location.state.loginSubscriberId===null) ? this.props.state.loginSubscriberId :this.props.location.state.loginSubscriberId;
        }
        
        this.state = {
            eventCategories: [],
            orgId: (this.props.location.state.orgId===null) ? this.props.state.orgId :this.props.location.state.orgId,
            subscriptionId: (this.props.location.state.subscriptionId ===null) ? this.props.state.subscriptionId:this.props.location.state.subscriptionId,          
            loginSubscriberId:AuthContext.loginSubscriberId,
            subscriptionName: this.props.location.state.subscriptionName,
            isNewSubscription:false,
            eventCategoryID:this.props.location.state.eventCategoryID,
            loaded: false
        };
    }

    async componentDidMount() {
        var eventCategoriesObject = await GetEventCategories();
        if (eventCategoriesObject != null) {
            this.setState({
                eventCategories: eventCategoriesObject,
                eventCategoryId: eventCategoriesObject[0].eventCategoryID,
                events: eventCategoriesObject[0].events,
                approverMail:eventCategoriesObject[0].approverEmailAddress,
                loaded: true
            });
        }
    }
    logOut() {
        AuthContext.logOut();
        AuthContext.clearCache();
    }

    getSelectedIndex(index){        
        this.setState({
          activeTab: index
        });
      } 

    render() {
        const { isNewSubscription, loaded } = this.state;
                

        const logOut =
        <button type="button" className="btn btn-link" onClick={this.logOut}>Log Out </button>
        const header = <div className="Header">
            <div className="Header-banner">
                <div className="Header-corner_img"></div></div>
            <div className="Header-title">
                Event Bus Broker
            </div>
            <p className="Header-welcome">
                Hello {AuthContext.getCachedUser().profile.name}.
            </p>
           
                
            <div className="Header-about">
            <Link to={{
                    pathname: '/About',
                    state: {    orgId: 1 }}}
                target="_About" rel='noopener noreferrer'>
               About
            </Link>&nbsp;&nbsp;&nbsp;&nbsp; <br></br>  <br></br><br></br>{logOut}
            
                  

                  </div>
        </div>
        if (loaded) {
            return (
                <React.Fragment>
                <div>{header}</div>
                
                 
               
               <Tabs>
               <TabList className="SubscriptionForm-paneltab">

               <Tab onClick={() =>this.getSelectedIndex(0)}
                className={(this.state.activeTab === 0) ? 'SubscriptionForm-subselect-active': 'SubscriptionForm-subunselect-active'} > 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Edit Subscription
                </Tab>

                <Tab onClick={() =>this.getSelectedIndex(1)}
                className={(this.state.activeTab === 1) ? 'SubscriptionForm-subselect-active': 'SubscriptionForm-subunselect-active'} >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Reports
                </Tab>

                <Tab onClick={() =>this.getSelectedIndex(2)}
                className={(this.state.activeTab === 2) ? 'SubscriptionForm-subselect-active': 'SubscriptionForm-subunselect-active'} >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Log
                </Tab>

                <Tab onClick={() =>this.getSelectedIndex(3)}
                className={(this.state.activeTab === 3) ? 'SubscriptionForm-subselect-active': 'SubscriptionForm-subunselect-active'} >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Events Example
                </Tab>

                <Tab onClick={() =>this.getSelectedIndex(4)}
                className={(this.state.activeTab === 4) ? 'SubscriptionForm-subselect-active': 'SubscriptionForm-subunselect-active'} >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Select Schema
                </Tab>
               
                </TabList>

                  <TabPanel> <SubscriptionForm orgId={this.state.orgId} subscriptionId={this.state.subscriptionId} 
                  loginSubscriberId={this.state.loginSubscriberId} isNewSubscription={isNewSubscription}
                  eventCategories={this.state.eventCategories}  eventCategoryId={this.state.eventCategoryId} events={this.state.events}
                  approverMail={this.state.approverMail}/>
			  	 </TabPanel>
                 
                  <TabPanel><SelectReports orgId={this.state.orgId} subscriptionId={this.state.subscriptionId} 
                  loginSubscriberId={this.state.loginSubscriberId} subscriptionName={this.state.subscriptionName} /></TabPanel>
                 
                  <TabPanel> <ViewSubscriptionChanges orgId={this.state.orgId} subscriptionId={this.state.subscriptionId} 
                  loginSubscriberId={this.state.loginSubscriberId} /></TabPanel>

                   <TabPanel> <EventsExample orgId={this.state.orgId} subscriptionId={this.state.subscriptionId} 
                  loginSubscriberId={this.state.loginSubscriberId} /></TabPanel>


                    <TabPanel> <SelectFields orgId={this.state.orgId} subscriptionId={this.state.subscriptionId} 
                   loginSubscriberId={this.state.loginSubscriberId} eventCategoryID= {this.state.eventCategoryID} /></TabPanel>               

                    </Tabs>
                
                </React.Fragment>
                );
        } else {
            return (<div>{header}
                <div className="Header-loading"> 
                <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} timeout={3000}/>                 
                </div>
            </div>);
        }
    }
}

export default ManageSubscription;