import axios from "axios";
import configData from "./../../../config.json";

const baseUri = configData.APIURL;
const apikey = configData.APIKEY;


export default async (subscription) => {
    
    try {
        const result = await axios({
            method: 'post',
            url: baseUri + `/subscriptions`,
            data: subscription,
            headers: {
               'Ocp-Apim-Subscription-Key': apikey,
               'Content-Type': 'application/json',                              
                'Access-Control-Allow-Origin' : '*' 
            }            
        });
        return result;
    } catch (error) {
        return error;
    }
}