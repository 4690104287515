export default (propsname,propsvalue,propssource) => {  
var isValid=true;
var propsField;
   
    if(propsname==='email')
    {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(propsvalue))
        {
            return true;
        }
        else
        {
            return (propssource + "Email is invalid");
        }
    }

    if(propsname==='numbers')
    { 
        for (var i = 0; i < propsvalue.length; i++) {   
            if(i===0)                        
            propsField='Retry Limit'
            else if(i===1)
            propsField='Retry Wait'
            else if(i===2)
            propsField='Message Age'
        if (/^[0-9]+$/.test(propsvalue[i]))
        {
            isValid=true;
        }
        else
        {           
            return (propssource + propsField + " is not a valid integer");            
        }        
    }
    return isValid;
    
    }

    return isValid;
     
}
