import axios from "axios";
import configData from "./../../../config.json";

const baseUri = configData.APIURL;
const apikey = configData.APIKEY;

export default async (subscriberId) => {
    try {
        await axios({
            method: 'delete',
            url: baseUri + `/subscribers/${subscriberId}`,  
            headers: { 'Ocp-Apim-Subscription-Key': apikey,
                       'Access-Control-Allow-Origin' : '*',
                       'Content-Type': 'application/json' }                           
        });
        return subscriberId;
    } catch (error) {
        return null;
    }
}