import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import GetSubscriptionsChanges from '../Services/GetSubscriptionChanges.js';
import SubscriptionChangesTable from './SubscriptionChangesTable';
import '../../../sass/header.scss';
import '../../../sass/viewchanges.scss';
import  AuthContext  from " ../../../src/Auth";

class ViewSubscriptionChanges extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orgId: this.props.orgId,            
            subscriptionId: this.props.subscriptionId,
            loginSubscriberId: AuthContext.loginSubscriberId,
            audits: [],
            noaudit: false,
            loaded: false,
            submitted: false
        };           
    }

    async componentDidMount() {
        const auditsObject = await GetSubscriptionsChanges(this.state.subscriptionId);
        if (auditsObject != null) {
            this.setState({
                audits: auditsObject,
                loaded: true
            })
        } else {
            this.setState({
                noaudit: true
            })
        }
    }
    render() {
        const { orgId,audits, loaded, noaudit } = this.state;
     
      

        const headerlink=
        <div className="ViewChanges-headerlink">
        <Link to={{
        pathname: '/Subscriptions',
        state: {    orgId: this.state.orgId,
                    subscriptionId: this.state.subscriptionId,
                    loginSubscriberId:this.state.loginSubscriberId }
        }}>Subscriptions </Link> {">"} Log</div>;

        if (loaded) {
            return (<div>
                {/* {header} */}
            {headerlink}
                <div className="ViewChanges">
                    <div className="ViewChanges-title">
                        Subscription Changes
                    </div>
                    <SubscriptionChangesTable data={audits} orgId={orgId} />                   
                </div>
            </div>);
        } else if (noaudit) {
            return (<div>  
                {/* {header}  */}
            {headerlink}
                <div className="ViewChanges">
                    <div className="ViewChanges-title">
                       Subscription Changes
                    </div>
                    <SubscriptionChangesTable />                  
                </div>
            </div>);
        } else {
            return (<div>
                {/* {header} */}
             {headerlink}
                <div className="Header-loading">
                <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} timeout={3000}/>        
                </div>
            </div>);
        }
    }
}

export default ViewSubscriptionChanges;