import React, { Component } from "react";
import '../../App.css';
import GetOrganizations from './Services/GetOrganizations.js';
import GetSubscriber from '../SubscriptionsManagement/Services/GetSubscriberbyUserID.js';
import PostSubscriber from '../../components/SubscriptionsManagement/Services/PostUserDetails.js';
import '../../sass/homepage.scss';
import '../../sass/header.scss';
import  AuthContext  from " ../../../src/Auth";


import { Link } from 'react-router-dom';

class SelectOrganization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orgId: 1,            
            organizations: [],
            existingSubscriber:false,
            username:AuthContext.getCachedUser().profile.name,
            userid:AuthContext.getCachedUser().userName,
            loaded: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = o => {
        this.setState(
            { orgId: o.target.value }
        );
    }

     handleSubmit = async() => {        
        const orgid = this.state.orgId;
        const username=AuthContext.getCachedUser().profile.name;
        const useremail=AuthContext.getCachedUser().userName;

        if(this.state.existingSubscriber === false)
        {            
            const subscriber=
            {
                Name:username,
                UserID:useremail,
                OrgID:orgid
            }

            AuthContext.loginSubscriberId= await PostSubscriber(subscriber);
        }       
            
        
        this.props.history.push({
            pathname: "/Subscriptions",
            state: {
                orgId: orgid,
                username:username,
                userid:useremail,
                loginSubscriberId:AuthContext.loginSubscriberId
            }
        });
    }

    componentDidMount = async () => {        
        const subscriberObject = await GetSubscriber(this.state.userid);                
        
        if (subscriberObject != null) {
            this.setState({
                existingSubscriber:true,
                subscriberId:subscriberObject.subscriberID
            })
            AuthContext.loginSubscriberId=subscriberObject.subscriberID; 
            this.props.history.push({
                pathname: "/Subscriptions",
                state: {
                    orgId: subscriberObject.orgID,
                    username:subscriberObject.name,
                    userid:subscriberObject.id,
                    loginSubscriberId:AuthContext.loginSubscriberId
                }                
            });
        }
        
       

        const organizationsObject = await GetOrganizations();
        if (organizationsObject != null) {
            this.setState({
                organizations: organizationsObject,
                loaded: true
            })
        }
    }

    onClose = () => {
        window.opener = null;
        window.open("", "_self");
        window.close();
      };

    logOut() {
        AuthContext.logOut();
        AuthContext.clearCache();
	}
    render() {
        const { organizations, loaded } = this.state;

        const organizationsDropdown =
            <div className="Homepage-org-dropdown">
                <select className="Homepage-org-text" value={this.state.orgId} onChange={this.handleChange}>
                    {organizations.map((org) => (
                        <option className="Homepage-org-text" key={org.orgID} value={org.orgID}>{org.description}</option>
                    ))}
                </select>
            </div>

        const registerButton =
            <button className="Homepage-register-button" onClick={this.handleSubmit}>Register</button>
            const logOut =
            <button type="button" className="btn btn-link" onClick={this.logOut}>Log Out </button>
        
        if (loaded) {
            return (<div>
                <div className="Header">
                    <div className="Header-banner">
                        <div className="Header-corner_img"></div></div>
                            <div className="Header-title">
                                Event Bus Broker
                            </div>
                            <p className="Header-welcome">
                            Hello {AuthContext.getCachedUser().profile.name}.
                             
                            </p>
                            <div className="Header-about">
                            <Link to={{
                    pathname: '/About',
                    state: {    orgId: 1 }}}
                target="_About" rel='noopener noreferrer'>
               About
            </Link>
              <br></br><br></br><br></br> {logOut}
                    </div> 
                        </div> 

                <div className="Homepage">
                    <div className="Homepage-welcome-msg">
                        Welcome to the Event Bus Broker.
                        </div>
                    <div className="Homepage-org-msg">
                    Select a department that you are registered to:
                            {organizationsDropdown}
                    </div>                    
                    {registerButton}
                </div>
            </div>);
        } else {
            return null;
        }
    }
}

export default SelectOrganization;