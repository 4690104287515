import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Moment from "moment";
import { withRouter } from 'react-router-dom';
import '../../sass/reports.scss';

class subscriptionReportTable extends Component {

    colFormatterManage = (cell) => {
        Moment.locale('en');        
        return (            
             Moment(cell).local().format('MM/DD/YYYY HH:mm:ss') 
        )
    }

     columnHover = (cell, row, enumObject, rowIndex) => {
        return cell
      }
     

    render() {       
        return (             
            <div className="Reports-columnstyle" >
                <BootstrapTable data={this.props.data} className="Reports-SubscriptionTable"> 
                    <TableHeaderColumn isKey dataField='row' hidden={true}  >
                        SubscriptionId
                    </TableHeaderColumn> 
                    <TableHeaderColumn  dataField='subscriptionName'width={'12%'}  columnTitle={this.columnHover}>
                        Subscription                        
                    </TableHeaderColumn>                   
                    <TableHeaderColumn dataField='sinkType' width={'7%'}>
                        Sink Type
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='startSubscriptionDT' dataFormat={this.colFormatterManage} width={'12%'}>
                       Subscription Start
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='endSubscriptionDT' dataFormat={this.colFormatterManage} width={'12%'}>
                       Subscription End
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='retryLimit' dataAlign='center' width={'7%'}>
                        Retry Limit
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='retryWait' dataAlign='center' width={'7%'}>
                        Retry Wait
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='ttL_TimeSeconds' dataAlign='center' width={'8%'}>
                       TTL Time
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='callbackEndpoint' width={'17%'} columnTitle={this.columnHover}>
                        Callback
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='qManager' width={'12%'}>
                        QManager
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='qName' width={'10%'} columnTitle={this.columnHover}>
                        QName
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='emailAddress' width={'14%'} columnTitle={this.columnHover}>
                        Email
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='jsonFormat' dataAlign='center' width={'8%'}>
                        JSON
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='successDeliveries' dataAlign='center' width={'8%'}>
                        Success
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='failedDeliveries' dataAlign='center' width={'8%'}>
                        Failed
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='maxDeliveryTime_ms' dataAlign='center' width={'9%'}>
                        Max Delivery(ms)
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='minDeliveryTime_ms' dataAlign='center' width={'9%'}>
                        Min Delivery(ms)
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='avgDeliveryTime_ms' dataAlign='center' columnClassName='Reports-rowstyle' width={'9%'}>
                        Avg Delivery(ms)
                    </TableHeaderColumn>                    
                </BootstrapTable>                               
             </div>
        );
    }
}

export default withRouter(subscriptionReportTable);