import axios from "axios";
import configData from "./../../../config.json";

const baseUri = configData.APIURL;
const apikey = configData.APIKEY;


export default async (subscriptionId, subscription,applyXSL=0) => {
    try {
        const result = await axios({
            method: 'put',
            url: baseUri + `/subscriptions/${subscriptionId}?applyXSL=${applyXSL}`,
            data: subscription,
            headers: {
                'Ocp-Apim-Subscription-Key': apikey,
               'Content-Type': 'application/json'             
            }
        });       
      
        return result;
    } catch (error) {
        return error;
    }
}