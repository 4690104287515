import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from 'react-router-dom';
import moment from "moment";
import '../../../sass/currentsubscriptions.scss';

class SubscriptionsTable extends Component {    

    colFormatterReports = (cell, row) => {        
        return (
            <Link to={{
                pathname: '/Reports',
                state: {
                orgId: row.orgID,
                subscriptionId: row.subscriptionID ,
                subscriptionName: row.subscriptionName,
                loginSubscriberId:this.props.loginSubscriberId 
                }               
            }}>
                {cell}
            </Link>
        )
    }
   

    colFormatterManage = (cell, row) => {   
        return (
            <Link to={{
                pathname: '/EditSubscription',
                state: {
                    orgId: row.orgID,
                    subscriptionId: row.subscriptionID,
                    subscriberId:row.subscriberID,
                    loginSubscriberId:this.props.loginSubscriberId,
                    subscriptionName: row.subscriptionName,
                    eventCategoryID: row.eventCategoryID
                }
            }}>
                {cell}
            </Link>
        )
    }

        colFormatterChangelog = (cell, row) => {            
            return (
                <Link to={{
                    pathname: '/ViewChanges',
                    state: {
                        orgId: row.orgID,
                        subscriptionId: row.subscriptionID,
                        loginSubscriberId:this.props.loginSubscriberId 
                    }
                }}>
                    {cell}
                </Link>
            )
    }

     colFormatterStatus = (fieldValue) => {  
        return ( fieldValue ==='Not Approved' ? 'CurrentSubscriptions-blue' : 'CurrentSubscriptions-green')        
    }

    colFormatterDateModified = (fieldValue) => {  
        return moment(fieldValue).format('MM/DD/YYYY');
    }     
   


    render() {
        
        const pagingoptions = {
            page: 1, 
            sizePerPage: 10,  // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 2,  // the pagination bar size.
            hideSizePerPage: false, // You can hide the dropdown for sizePerPage
            alwaysShowAllBtns: true, // Always show next and previous button
            withFirstAndLast: true, //> Hide the going to First and Last page button
            paginationPosition: 'bottom' ,
            sizePerPageList: [ {
                text: 'Show 5 per page', value: 5
              }, {
                text: 'Show 10 per page', value: 10
              }, {
                text: 'Show All', value: ((this.props.data ===undefined)?0:this.props.data.length)
              } ], // you can change the dropdown list for size per page
          };

        return (
            <div className="CurrentSubscriptions-table">
                <BootstrapTable data={this.props.data} pagination options={pagingoptions}>
                    <TableHeaderColumn isKey dataField='subscriptionName'  
                    filter={ { type: 'TextFilter', delay: 500 , style: { height:'25px' }, placeholder:'Enter Name' } }
                    dataFormat={this.colFormatterManage} width={'24%'}>
                        NAME
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='eventName' width={'17%'}>
                        CATEGORY
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='contactEmail' width={'24%'}>
                        CONTACT
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='delivery' width={'15%'}>
                        DELIVERY
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='status'  width={'10%'} columnClassName={this.colFormatterStatus} >                   
                        STATUS
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='endSubscriptionDT' dataFormat={this.colFormatterDateModified} width={'12%'}>
                        LAST MODIFIED
                    </TableHeaderColumn>
                    
                </BootstrapTable>
            </div>
        );
    }

}

export default SubscriptionsTable;