import React, { Component } from "react";
import { Form, FormGroup, Label, Input, Col, Row } from 'reactstrap';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import DateTimePicker from 'react-datetime-picker';
import moment from "moment";
import Loader from 'react-loader-spinner';
import PostSubscription from '../Services/PostSubscription.js';
import GetSinksBySubscription from '../Services/GetSinksBySubscription.js';
import PostSink from '../Services/PostSink.js';
import CheckInputChanges from './Services/CheckInputChanges.js';
import ValidateDate from '../../GlobalServices/ValidateDate.js';
import UpdateSubscription from "../Services/UpdateSubscription.js";
import { withRouter } from 'react-router-dom';
import DeleteSubscription from "../Services/DeleteSubscription.js";
import DeleteSink from "../Services/DeleteSink.js";
import UpdateSink from "../Services/UpdateSink.js";
import NotifyApprover from "../Services/NotifyApprover.js";
import '../../../sass/sinksform.scss';
import '../../../sass/header.scss';
import  AuthContext  from " ../../../src/Auth";
import CheckInputData from "./Services/CheckInputData.js";
import ValidateSubscription from "./Services/ValidateSubscription.js";
import ValidateSink from "./Services/ValidateSink.js";
import { AiFillDelete } from "react-icons/ai";
import configData from "./../../../config.json";

class SinksForm extends Component {
    constructor(props) {
        super(props);  
         let currentDate = new Date();
        let defaultEndDate= new Date();
        defaultEndDate.setHours(defaultEndDate.getHours(),defaultEndDate.getMinutes()+10,0,0);
        this.state = {
            existingSubscription: false,
            orgId: this.props.orgId,
            subscriptionId : this.props.subscriptionId,
            loginSubscriberId: AuthContext.loginSubscriberId,
            lblMessage:null,
            flagCreated:0,
            lblMessageColor:null,
            loaded:false,
            existSink:false,
            isAPI:"N",
            existMQ:false,
            isSBQ:"N",
            activeTab:0,            
            existEmail:false,
            isEmail:"N",
            sinkIdAPI: null,
            url: null,
            username: 'ocp-apim-subscription-key',
            password: null,
            startDT_API: currentDate,
            endDT_API: defaultEndDate,
            retryLimit_API: 3,
            retryWait_API: 10,
            messageAge_API: 60,
            jsonchecked_API:true,
            sinkIdQueue: null,
            queueName: null,
            startDT_Queue: currentDate,
            endDT_Queue:defaultEndDate,
            retryLimit_Queue: 3,
            retryWait_Queue: 10,
            messageAge_Queue: 60,
            jsonchecked_Queue:true,
            sinkIdEmail: null,
            email: null,
            startDT_Email: currentDate,
            endDT_Email: defaultEndDate,
            retryLimit_Email: 3,
            retryWait_Email: 10,
            messageAge_Email: 60,
            jsonchecked_Email:true,        
            flagSubscriptionCreated:null,
            IsAPIChanged:this.props.subscription.IsChanged,
            IsQueueChanged:this.props.subscription.IsChanged,
            IsMailChanged:this.props.subscription.IsChanged,
            labelbgcolor:null,  
            isLoading:false, 
            bgcolorURL:"validatedmessage",         
            bgcolorUserName:"validatedmessage",
            bgcolorPassword:"validatedmessage",
            bgcolorAPIStartDate:"validatedmessage",
            bgcolorAPIEndDate:"validatedmessage",
            bgcolorretryLimitAPI:"validatedmessage",
            bgcolorretryWaitAPI:"validatedmessage",
            bgcolormessageAgeAPI:"validatedmessage",
            bgcolorQueueName:"validatedmessage",
            bgcolorQueueStartDate:"validatedmessage",
            bgcolorQueueEndDate:"validatedmessage",
            bgcolorretryLimitQueue:"validatedmessage",
            bgcolorretryWaitQueue:"validatedmessage",
            bgcolormessageAgeQueue:"validatedmessage",
            bgcolorEmailName:"validatedmessage",
            bgcolorEmailStartDate:"validatedmessage",
            bgcolorEmailEndDate:"validatedmessage",
            bgcolorretryLimitEmail:"validatedmessage",
            bgcolorretryWaitEmail:"validatedmessage",
            bgcolormessageAgeEmail:"validatedmessage"
        }
        this.handleSave = this.handleSave.bind(this);
        this.handleDelete=this.handleDelete.bind(this);
        this.handleCancel=this.handleCancel.bind(this);
        this.handleApproved=this.handleApproved.bind(this); 
        this.handleDeleteSink=this.handleDeleteSink.bind(this);
        this.setAPIBackgroundColor=this.setAPIBackgroundColor.bind(this); 
        this.setQueueBackgroundColor=this.setQueueBackgroundColor.bind(this); 
        this.setEmailBackgroundColor=this.setEmailBackgroundColor.bind(this); 
    }


    setAPIBackgroundColor (colorvalue) {
        this.props.setBackgroundColor(("validatedmessage"));
        var apiValidated=true;        
                        
        if((colorvalue.indexOf("Username")>=0))      
        {     
            apiValidated=false;
            this.setState({ bgcolorUserName : colorvalue,bgcolorretryLimitAPI:"validatedmessage",
            bgcolorretryWaitAPI:"validatedmessage",bgcolormessageAgeAPI:"validatedmessage",bgcolorAPIEndDate:"validatedmessage",
            bgcolorAPIStartDate : "validatedmessage",bgcolorPassword:"validatedmessage",bgcolorURL:"validatedmessage"});        
        }
        else if(colorvalue.indexOf("Password")>=0)    
        {
            apiValidated=false;
            this.setState({ bgcolorPassword : colorvalue,bgcolorUserName:"validatedmessage",bgcolorURL:"validatedmessage",
            bgcolorretryLimitAPI:"validatedmessage",
            bgcolorretryWaitAPI:"validatedmessage",bgcolormessageAgeAPI:"validatedmessage",bgcolorAPIEndDate:"validatedmessage",
            bgcolorAPIStartDate : "validatedmessage"});
        }
        else if((colorvalue.indexOf("Start")>=0) || (colorvalue.indexOf("datevalerrormessage")>=0))
        {
            apiValidated=false;
            this.setState({ bgcolorAPIStartDate : "starterrormessage",bgcolorPassword:"validatedmessage",bgcolorUserName:"validatedmessage",
            bgcolorretryLimitAPI:"validatedmessage",bgcolorURL:"validatedmessage",
            bgcolorretryWaitAPI:"validatedmessage",bgcolormessageAgeAPI:"validatedmessage",bgcolorAPIEndDate:"validatedmessage"});
        }
        else if(colorvalue.indexOf("End")>=0)   
        { 
            apiValidated=false;
            this.setState({ bgcolorAPIEndDate:"enderrormessage",bgcolorAPIStartDate : "validatedmessage",bgcolorPassword:"validatedmessage",bgcolorURL:"validatedmessage",
            bgcolorUserName:"validatedmessage",bgcolorretryWaitAPI:"validatedmessage",bgcolormessageAgeAPI:"validatedmessage",bgcolorretryLimitAPI:"validatedmessage"});        
        }
        else if(colorvalue.indexOf("Retry Limit")>=0)    
        {   
            apiValidated=false;     
            this.setState({ bgcolorretryLimitAPI:"Limiterrormessage",bgcolorAPIEndDate:"validatedmessage",bgcolorAPIStartDate : "validatedmessage",
            bgcolorPassword:"validatedmessage",bgcolorUserName:"validatedmessage",bgcolorretryWaitAPI:"validatedmessage",bgcolormessageAgeAPI:"validatedmessage",bgcolorURL:"validatedmessage"});
        }
        else if(colorvalue.indexOf("Retry Wait")>=0)   
        { 
            apiValidated=false;        
            this.setState({ bgcolorretryLimitAPI:"validatedmessage",bgcolorretryWaitAPI:"Limiterrormessage",bgcolorAPIEndDate:"validatedmessage",bgcolorURL:"validatedmessage",
            bgcolorAPIStartDate : "validatedmessage",bgcolorPassword:"validatedmessage",bgcolorUserName:"validatedmessage",bgcolormessageAgeAPI:"validatedmessage"});
        }
        else if(colorvalue.indexOf("Message")>=0) 
        { 
            apiValidated=false;         
            this.setState({ bgcolorretryLimitAPI:"validatedmessage",bgcolorretryWaitAPI:"validatedmessage",bgcolormessageAgeAPI:"Ageerrormessage",
            bgcolorAPIEndDate:"validatedmessage",bgcolorAPIStartDate : "validatedmessage",
            bgcolorPassword:"validatedmessage",bgcolorUserName:"validatedmessage",bgcolorURL:"validatedmessage"});
        }
        else if (colorvalue.indexOf("apiurl")>=0)
        {
            apiValidated=false;
            this.setState({ bgcolorURL : colorvalue,bgcolorUserName:"validatedmessage",bgcolorretryLimitAPI:"validatedmessage",
            bgcolorretryWaitAPI:"validatedmessage",bgcolormessageAgeAPI:"validatedmessage",bgcolorAPIEndDate:"validatedmessage",
            bgcolorAPIStartDate : "validatedmessage",bgcolorPassword:"validatedmessage"});
        }
        else
        {               
            this.setDefaultColor("validatedmessage");  
        }  
        
        if(apiValidated===false)
        {            
            this.setState({bgcolorQueueStartDate : "validatedmessage",bgcolorQueueName:"validatedmessage",
            bgcolorretryLimitQueue:"validatedmessage",bgcolorretryWaitQueue:"validatedmessage",bgcolormessageAgeQueue:"validatedmessage",
            bgcolorQueueEndDate:"validatedmessage",bgcolorretryLimitEmail:"validatedmessage",bgcolorretryWaitEmail:"validatedmessage",bgcolormessageAgeEmail:"validatedmessage",
            bgcolorEmailEndDate:"validatedmessage",bgcolorEmailStartDate : "validatedmessage",bgcolorEmailName:"validatedmessage"});

        }

        
      }

    

      setQueueBackgroundColor (colorvalue) {      
         
        var queueValidated=true;
         this.props.setBackgroundColor(("validatedmessage"));

        if((colorvalue.indexOf("Start")>=0) || (colorvalue.indexOf("datevalerrormessage")>=0))    
        {  
            queueValidated=false;      
            this.setState({ bgcolorQueueStartDate : "starterrormessage",bgcolorQueueName:"validatedmessage",
            bgcolorretryLimitQueue:"validatedmessage",bgcolorretryWaitQueue:"validatedmessage",bgcolormessageAgeQueue:"validatedmessage",
            bgcolorQueueEndDate:"validatedmessage"});
        }
        else if(colorvalue.indexOf("End")>=0)   
        { 
            queueValidated=false;      
            this.setState({ bgcolorQueueEndDate:"enderrormessage",bgcolorQueueStartDate : "validatedmessage",
            bgcolorQueueName:"validatedmessage",bgcolorretryLimitQueue:"validatedmessage",bgcolorretryWaitQueue:"validatedmessage",
            bgcolormessageAgeQueue:"validatedmessage"});   
        }     
        else if(colorvalue.indexOf("Retry Limit")>=0)     
        {   
            queueValidated=false;          
            this.setState({queueValidated:false, bgcolorretryLimitQueue:"Limiterrormessage",bgcolorQueueEndDate:"validatedmessage",bgcolorQueueStartDate : "validatedmessage",
            bgcolorQueueName:"validatedmessage",bgcolorretryWaitQueue:"validatedmessage",bgcolormessageAgeQueue:"validatedmessage"});
        }
        else if(colorvalue.indexOf("Retry Wait")>=0)   
        { 
            queueValidated=false;              
            this.setState({ queueValidated:false,bgcolorretryLimitQueue:"validatedmessage",bgcolorQueueEndDate:"validatedmessage",
            bgcolorQueueStartDate : "validatedmessage",bgcolorQueueName:"validatedmessage",bgcolorretryWaitQueue:"Waiterrormessage",bgcolormessageAgeQueue:"validatedmessage"});
        }
        else if(colorvalue.indexOf("Message")>=0)   
        {  
            queueValidated=false;             
            this.setState({ queueValidated:false,bgcolorretryLimitQueue:"validatedmessage",bgcolorretryWaitQueue:"validatedmessage",bgcolormessageAgeQueue:"Ageerrormessage",
            bgcolorQueueEndDate:"validatedmessage",bgcolorQueueStartDate : "validatedmessage",
            bgcolorQueueName:"validatedmessage"});
        }
        else
        {   
            this.setDefaultColor("validatedmessage");  
        }   
        if(queueValidated===false)
        {
            this.setState({bgcolorURL : "validatedmessage",bgcolorUserName:"validatedmessage",bgcolorretryLimitAPI:"validatedmessage",
            bgcolorretryWaitAPI:"validatedmessage",bgcolormessageAgeAPI:"validatedmessage",bgcolorAPIEndDate:"validatedmessage",
            bgcolorAPIStartDate : "validatedmessage",bgcolorPassword:"validatedmessage",bgcolorretryLimitEmail:"validatedmessage",bgcolorretryWaitEmail:"validatedmessage",bgcolormessageAgeEmail:"validatedmessage",
            bgcolorEmailEndDate:"validatedmessage",bgcolorEmailStartDate : "validatedmessage",bgcolorEmailName:"validatedmessage"});

        }    
      }

      setEmailBackgroundColor (colorvalue) {      
          var emailValidated=true;
        this.props.setBackgroundColor(("validatedmessage"));
        if((colorvalue.indexOf("Start")>=0) || (colorvalue.indexOf("datevalerrormessage")>=0)) 
        {          
            this.setState({emailValidated:false, bgcolorEmailStartDate : "starterrormessage",bgcolorEmailName:"validatedmessage",bgcolorEmailEndDate:"validatedmessage",
            bgcolorretryLimitEmail:"validatedmessage",bgcolorretryWaitEmail:"validatedmessage",bgcolormessageAgeEmail:"validatedmessage"});
            emailValidated=false;
        }
        else if(colorvalue.indexOf("End")>=0)  
        {  
            this.setState({ emailValidated:false,bgcolorEmailEndDate:"enderrormessage",bgcolorEmailStartDate : "validatedmessage",bgcolorEmailName:"validatedmessage",
            bgcolorretryLimitEmail:"validatedmessage",bgcolorretryWaitEmail:"validatedmessage",bgcolormessageAgeEmail:"validatedmessage"});        
            emailValidated=false;
        }
        else if(colorvalue.indexOf("Retry Limit")>=0)       
        {     
            this.setState({emailValidated:false, bgcolorretryLimitEmail:"Limiterrormessage",bgcolorEmailEndDate:"validatedmessage",bgcolorEmailStartDate : "validatedmessage",
            bgcolorEmailName:"validatedmessage",bgcolorretryWaitEmail:"validatedmessage",bgcolormessageAgeEmail:"validatedmessage"});
            emailValidated=false;
        }
        else if(colorvalue.indexOf("Retry Wait")>=0)            
        {
            this.setState({emailValidated:false, bgcolorretryLimitEmail:"validatedmessage",bgcolorretryWaitEmail:"Limiterrormessage",bgcolorEmailEndDate:"validatedmessage",
            bgcolorEmailStartDate : "validatedmessage",bgcolorEmailName:"validatedmessage",bgcolormessageAgeEmail:"validatedmessage"});
            emailValidated=false;
        }
        else if(colorvalue.indexOf("Message")>=0)            
        {
            this.setState({ emailValidated:false,bgcolorretryLimitEmail:"validatedmessage",bgcolorretryWaitEmail:"validatedmessage",
            bgcolorEmailEndDate:"validatedmessage",bgcolorEmailStartDate : "validatedmessage",bgcolormessageAgeEmail:"Ageerrormessage",
            bgcolorEmailName:"validatedmessage"});
            emailValidated=false;
        }
        else if(colorvalue.indexOf("Email")>=0)
        {
            this.setState({ emailValidated:false,bgcolorretryLimitEmail:"validatedmessage",bgcolorretryWaitEmail:"validatedmessage",bgcolormessageAgeEmail:"validatedmessage",
            bgcolorEmailEndDate:"validatedmessage",bgcolorEmailStartDate : "validatedmessage",bgcolorEmailName:colorvalue });
            emailValidated=false;
        }

        else
        { 
            this.setDefaultColor("validatedmessage");            
        }    
        
        if(emailValidated===false)
        {
            this.setState({bgcolorQueueStartDate : "validatedmessage",bgcolorQueueName:"validatedmessage",
            bgcolorretryLimitQueue:"validatedmessage",bgcolorretryWaitQueue:"validatedmessage",bgcolormessageAgeQueue:"validatedmessage",
            bgcolorQueueEndDate:"validatedmessage",		
			bgcolorURL : "validatedmessage",bgcolorUserName:"validatedmessage",bgcolorretryLimitAPI:"validatedmessage",
            bgcolorretryWaitAPI:"validatedmessage",bgcolormessageAgeAPI:"validatedmessage",bgcolorAPIEndDate:"validatedmessage",
            bgcolorAPIStartDate : "validatedmessage",bgcolorPassword:"validatedmessage" });

        } 
      }

      setDefaultColor(bgcolorname)
      {
        
        this.setState({ bgcolorEmailName:bgcolorname,                
            bgcolorEmailStartDate:bgcolorname,
            bgcolorEmailEndDate:bgcolorname,
            bgcolorretryLimitEmail:bgcolorname,
            bgcolorretryWaitEmail:bgcolorname,
            bgcolormessageAgeEmail:bgcolorname,
            bgcolorQueueName:bgcolorname,                
            bgcolorQueueStartDate:bgcolorname,
            bgcolorQueueEndDate:bgcolorname,
            bgcolorretryLimitQueue:bgcolorname,
            bgcolorretryWaitQueue:bgcolorname,
            bgcolormessageAgeQueue:bgcolorname,
            bgcolorUserName:bgcolorname,
            bgcolorPassword:bgcolorname,
            bgcolorAPIStartDate:bgcolorname,
            bgcolorAPIEndDate:bgcolorname,
            bgcolorretryLimitAPI:bgcolorname,
            bgcolorretryWaitAPI:bgcolorname,
            bgcolormessageAgeAPI:bgcolorname,
            bgcolorURL:bgcolorname
                        }); 
      }

    formatDT = (dt) => {
        return moment(dt).format('YYYY-MM-DDTHH:mm:ss');
    }
    handleApproved = o => {        
        const subscriptionId = this.state.subscriptionId;
        this.setState({isLoading:true });   
        NotifyApprover(subscriptionId);        
        this.setState({
            lblMessage: "An email has been sent to the approver.",
            lblMessageColor:"#009933",
            isLoading:false
        })
         
    }

    handleDelete = async () => {        
        var subscriptionId = this.props.location.state.subscriptionId; 
        const orgId = this.state.orgId;  
        this.setState({isLoading:true });   
        await DeleteSubscription(subscriptionId);        
        this.setState({isLoading:false });
        this.props.history.push({
            pathname: "/Subscriptions",
            state: {
                orgId: orgId,
                loginSubscriberId:this.state.loginSubscriberId
            }
        });
    }

    handleDeleteSink = async (sinkId,deliveryType) => {                      
        this.setState({isLoading:true });   
        await DeleteSink(sinkId);        
        this.setState({
            lblMessage: "Subscription Updated" ,
            lblMessageColor:"#009933",
            isLoading:false            
        })
        if (deliveryType==='C')
        this.setState({ isAPI:"N",
                        existSink:false,
                        url:null,
                        username:null,
                        password:null,
                        sinkIdAPI:null});
        else if (deliveryType==='Q')
            this.setState({isSBQ:"N",
                           existMQ:false,
                           queueName:null,
                            sinkIdQueue:null});
        else if (deliveryType==='E')
            this.setState({isEmail:"N",
                           existEmail:false,
                           email:null,
                           sinkIdEmail:null });

    }


    handleCheck= async () => {
        this.setState({jsonchecked: !this.state.jsonchecked })
    };

    handleCancel= s => {
        s.preventDefault();
        this.props.history.push({
            pathname: "/Subscriptions",
            state: {
                orgId: this.state.orgId,
                loginSubscriberId:this.state.loginSubscriberId
            }
        });
    }

    handleSave = async () => {
        const { sinkIdAPI, url, username, password, startDT_API, endDT_API, retryLimit_API, retryWait_API, messageAge_API,jsonchecked_API} = this.state;
        const { sinkIdQueue, queueName, startDT_Queue, endDT_Queue, retryLimit_Queue, retryWait_Queue, messageAge_Queue,jsonchecked_Queue } = this.state;
        const { sinkIdEmail, email, startDT_Email, endDT_Email, retryLimit_Email, retryWait_Email, messageAge_Email,jsonchecked_Email } = this.state;
        
        var isSuccess=true;
        var subscription = this.props.subscription;
        var subscriptionId = 
        ((this.props.subscriptionId !== null) && ((typeof(this.props.subscriptionId))!=='undefined'))?this.props.subscriptionId:this.state.subscriptionId;
        var propsSubscription = [subscription.SubscriptionName, subscription.Purpose, subscription.ContactEmail,
            subscription.EventCategoryID, subscription.TopicsOfInterest];
        
        
        this.setState({ lblMessage:null }); 

        const sinkAPI = {
            SubscriptionID: subscriptionId,
            SinkType: 'C',
            StartSubscriptionDT: this.formatDT(startDT_API),
            EndSubscriptionDT: this.formatDT(endDT_API),
            RetryLimit: retryLimit_API,
            RetryWait: retryWait_API,
            TTL_TimeSeconds: messageAge_API,
            CallbackEndpoint: url,
            AuthNUid: username,
            AuthNPwd: password,
            jsonFormat:Number(Boolean(jsonchecked_API)) 
        }
        const sinkQueue = {
            SubscriptionID: subscriptionId,
            SinkType: 'Q',
            StartSubscriptionDT: this.formatDT(startDT_Queue),
            EndSubscriptionDT: this.formatDT(endDT_Queue),
            RetryLimit: retryLimit_Queue,
            RetryWait: retryWait_Queue,
            TTL_TimeSeconds: messageAge_Queue,
            QManager: null,
            QName: queueName,
            jsonFormat:Number(Boolean(jsonchecked_Queue))
        }
        const sinkEmail = {
            SubscriptionID: subscriptionId,
            SinkType: 'E',
            StartSubscriptionDT: this.formatDT(startDT_Email),
            EndSubscriptionDT: this.formatDT(endDT_Email),
            RetryLimit: retryLimit_Email,
            RetryWait: retryWait_Email,
            TTL_TimeSeconds: messageAge_Email,
            EmailAddress: email,
            jsonFormat:Number(Boolean(jsonchecked_Email))
        }

        var propsAPI = [url, username, password, startDT_API, endDT_API, retryLimit_API, retryWait_API, messageAge_API]; 
        var propsAPInumber=[retryLimit_API, retryWait_API, messageAge_API];    
        var propsQueue = [queueName, '','',startDT_Queue, endDT_Queue, retryLimit_Queue, retryWait_Queue, messageAge_Queue];
        var propsQueuenumber=[retryLimit_Queue, retryWait_Queue, messageAge_Queue];
        var propsEmail = [email,'','', startDT_Email, endDT_Email, retryLimit_Email, retryWait_Email, messageAge_Email];  
        var propsEmailnumber=[retryLimit_Email, retryWait_Email, messageAge_Email];   
        var subvalidationmessage,cssName,result,sinkValidationMessage;
        
         if(ValidateSubscription(propsSubscription)!==true)
         { 
             subvalidationmessage=ValidateSubscription(propsSubscription)
             cssName=subvalidationmessage.substr(0,subvalidationmessage.indexOf(' '));
                  
          this.setState({
                       lblMessage:subvalidationmessage ,
                       lblMessageColor:"red",
                       labelbgcolor:"errormessage"                      
                   })                                      
                   this.props.setBackgroundColor((cssName+"errormessage"));
                   return false;
         }
         else if(CheckInputData('email',subscription.ContactEmail,'Contact Email: ')!==true)
         { 
            subvalidationmessage=CheckInputData('email',subscription.ContactEmail,'Contact Email: ');
            cssName=subvalidationmessage.substr(0,subvalidationmessage.indexOf(' '));            

          this.setState({
                       lblMessage:subvalidationmessage,
                       lblMessageColor:"red" 
                   })
                   this.props.setBackgroundColor((cssName+"errormessage"));
                   return false;
         }
         else if(ValidateSink(propsAPI,'API')!==true)
         {    
        
            sinkValidationMessage=ValidateSink(propsAPI,'API');            
          
          if((sinkValidationMessage.indexOf("API URL")>=0))          
                cssName="apiurl"
          else
                cssName=sinkValidationMessage.substr(sinkValidationMessage.indexOf(': ')+2,
                    (sinkValidationMessage.indexOf('is') - sinkValidationMessage.indexOf(': ')-3));  

          
          this.setState({
                       lblMessage:sinkValidationMessage ,
                       lblMessageColor:"red" 
                   })
                 this.setAPIBackgroundColor((cssName+"errormessage"));
                   return false;
         }
         else if(ValidateDate(startDT_API,endDT_API)!==true)
         { 
            sinkValidationMessage=ValidateDate(startDT_API,endDT_API);            
            

          this.setState({
                       lblMessage: sinkValidationMessage,
                       lblMessageColor:"red"                       
                   })
                   this.setAPIBackgroundColor("datevalerrormessage");
                   return false;
         }                  

        else if(CheckInputData('numbers',propsAPInumber,'API Sink: ')!==true)
         {
            sinkValidationMessage=CheckInputData('numbers',propsAPInumber,'API Sink: ');            
            
            cssName=sinkValidationMessage.substr(sinkValidationMessage.indexOf(': ')+2,
            (sinkValidationMessage.indexOf('is') - sinkValidationMessage.indexOf(': ')-3));  

          this.setState({
                       lblMessage: sinkValidationMessage,
                       lblMessageColor:"red" 
                   })
                   this.setAPIBackgroundColor((cssName+"errormessage"));
                   return false;
         }
         else if(ValidateSink(propsQueue,'Queue')!==true)
         {         

            sinkValidationMessage=ValidateSink(propsQueue,'Queue');
            
            cssName=sinkValidationMessage.substr(sinkValidationMessage.indexOf(': ')+2,
            (sinkValidationMessage.indexOf('is') - sinkValidationMessage.indexOf(': ')-3));  

          this.setState({
                       lblMessage: sinkValidationMessage,
                       lblMessageColor:"red" 
                   })
                   this.setQueueBackgroundColor((cssName+"errormessage"));
                   return false;
         }
         else if(ValidateDate(startDT_Queue,endDT_Queue)!==true)
         { 
            sinkValidationMessage=ValidateDate(startDT_Queue,endDT_Queue);            
            

          this.setState({
                       lblMessage: sinkValidationMessage,                       
                       lblMessageColor:"red"                       
                   })
                   this.setQueueBackgroundColor("datevalerrormessage");
                   return false;
         }                   


       
        else if(CheckInputData('numbers',propsQueuenumber,'Service Bus Sink: ')!==true)
        {   
            sinkValidationMessage= CheckInputData('numbers',propsQueuenumber,'Service Bus Sink: ');
            
            cssName=sinkValidationMessage.substr(sinkValidationMessage.indexOf(': ')+2,
            (sinkValidationMessage.indexOf('is') - sinkValidationMessage.indexOf(': ')-3));  

          this.setState({
                       lblMessage: sinkValidationMessage,
                       lblMessageColor:"red" 
                   })
                   this.setQueueBackgroundColor((cssName+"errormessage"));
                   return false;
       
        }
        else if(ValidateSink(propsEmail,'email')!==true)
         {    
            sinkValidationMessage=ValidateSink(propsEmail,'email');

            cssName=sinkValidationMessage.substr(sinkValidationMessage.indexOf(': ')+2,
            (sinkValidationMessage.indexOf('is') - sinkValidationMessage.indexOf(': ')-3)); 

          this.setState({
                       lblMessage: sinkValidationMessage,
                       lblMessageColor:"red" 
                   })
                   this.setEmailBackgroundColor((cssName+"errormessage"));
                   return false;
         }        
         else if(ValidateDate(startDT_Email,endDT_Email)!==true)
         { 
            sinkValidationMessage=ValidateDate(startDT_Email,endDT_Email);            

          this.setState({
                       lblMessage: sinkValidationMessage,                       
                       lblMessageColor:"red"                       
                   })
                   this.setEmailBackgroundColor("datevalerrormessage");
                   return false;
         }       
        else if(CheckInputData('numbers',propsEmailnumber,'Email Sink: ')!==true)
        {  
            sinkValidationMessage= CheckInputData('numbers',propsEmailnumber,'Email Sink: ');
            
            cssName=sinkValidationMessage.substr(sinkValidationMessage.indexOf(': ')+2,
            (sinkValidationMessage.indexOf('is') - sinkValidationMessage.indexOf(': ')-3));  

          this.setState({
                       lblMessage: sinkValidationMessage,
                       lblMessageColor:"red" 
                   })
                   this.setEmailBackgroundColor((cssName+"errormessage"));
                   return false;
        }
        else if((CheckInputData('email',email,'Email Sink: ')!==true) && (email!=='') && (email!==null))        
        {  
            sinkValidationMessage=CheckInputData('email',email,'Email Sink: ');            

             cssName=sinkValidationMessage.substr(sinkValidationMessage.indexOf(': ')+2,
             (sinkValidationMessage.indexOf('is') - sinkValidationMessage.indexOf(': ')-3)); 
 
         this.setState({
                      lblMessage: sinkValidationMessage,
                      lblMessageColor:"red" 
                  })
                  this.setEmailBackgroundColor((cssName+"errormessage"));
                  return false;
        }
        
        if(isSuccess)
        {   
            
            this.props.setBackgroundColor(("validatedmessage"));  
            this.setAPIBackgroundColor(("validatedmessage")); 
            this.setQueueBackgroundColor(("validatedmessage"));
            this.setEmailBackgroundColor(("validatedmessage"));

            var isDuplicate=false; 
             
            // Edit Subscription
            if ((subscriptionId !== null) && ((typeof(subscriptionId))!=='undefined')) { 
                var applyXSL=0;

                this.setState({
                flagSubscriptionCreated:0,
                isLoading:true });   

                if(this.props.subscription.IsChanged === true)
                {
                result = await UpdateSubscription(subscriptionId, subscription,applyXSL);                 
                if( result.data!=null && result.data.subscriptionID!=null && result.data.subscriptionID>0)
                               {                   
                   this.setState({
                       lblMessage: "Subscription Updated" ,
                       lblMessageColor:"#009933",
                       isLoading:false
                   })
                }
                else    
               {
                if(result.response.statusText==="Conflict")
                {                    
                    this.setState({
                        lblMessage: "Subscription with same Name,Email and Events already exists",
                        lblMessageColor:"##0099cc"
                    })
                    isDuplicate=true;
                }
                  else ///if(result.data.statusText==="BadRequest") //(this.props.subscription.IsChanged === true )
                   {                                               
                        this.setState({
                            lblMessage: result.response.data.message,
                            lblMessageColor:"red"
                        })
                   }
                  
               }
            }
        } 
            // New Subscription
        else 
        {
            this.setState({isLoading:true})

            result = await PostSubscription(subscription);
            
            if( result.data!=null && result.data.subscriptionID>0)
            {
                NotifyApprover(result.data.subscriptionID);  
                subscriptionId=result.data.subscriptionID;                 
                this.setState({
                flagCreated:1,
                flagSubscriptionCreated:1,
                isLoading:false,
                subscriptionId: subscriptionId
            })                
            }
            else    
            {
                
            isDuplicate=true;
            const response = result.response.data.message.split(':');                
            this.setState({
                lblMessage: result.response.data.message,
                lblMessageColor:"red",
                isLoading:false
            })
            subscription.subscriberId=response[1];
            } 
            
        }
          
         
        if(isDuplicate)
        {
            if (subscriptionId == null) {  
                this.props.history.push({
                    pathname: '/EditSubscription',
                    state: {
                        orgId: subscription.OrgID,
                        subscriptionId:subscription.subscriberId,
                        subscriberId:this.state.loginSubscriberId,
                        loginSubscriberId:this.state.loginSubscriberId
                    }
                });
                window.location.reload();
            }
        }
        else    
        {

            if (CheckInputChanges(propsAPI)) 
            {  
                sinkAPI.SubscriptionID=subscriptionId;            
                if (sinkIdAPI != null) {               
                    await UpdateSink(sinkIdAPI, sinkAPI);
                    if(this.state.IsAPIChanged === true)
                    {                        
                        this.setState({
                            lblMessage: "Subscription Updated",
                            lblMessageColor:"#009933",
                            isLoading:false 
                        })
                    }
                    
                } else {
                    var createdSinkIdAPI = await PostSink(sinkAPI);
                    this.setState({sinkIdAPI: createdSinkIdAPI});
                    if(this.state.sinkIdAPI!=null)  
                    {                    
                        if(this.state.flagSubscriptionCreated ===1)
                        {      
                            this.setState({
                                flagCreated:1,
                                isLoading:false,
                                isAPI:"Y",
                                existSink:true
                            })
                        }
                        else
                        {
                            this.setState({
                                lblMessage: "Subscription Updated",
                                lblMessageColor:"#009933",
                                isLoading:false,
                                isAPI:"Y",
                                existSink:true
                            })
                        }
                    }
                }
            }
    
           if (CheckInputChanges(propsQueue)) {
            sinkQueue.SubscriptionID=subscriptionId;
                if (sinkIdQueue != null) {
                    await UpdateSink(sinkIdQueue, sinkQueue);
                    if(this.state.IsQueueChanged === true)
                    {                        
                        this.setState({
                            lblMessage: "Subscription Updated" ,
                            lblMessageColor:"#009933",
                            isLoading:false
                        })
                    }                
                } else {                
                    var createdSinkIdQueue = await PostSink(sinkQueue);
                    this.setState({sinkIdQueue: createdSinkIdQueue});                      
                    if(this.state.flagSubscriptionCreated ===1)   
                    {      
                    this.setState({
                        flagCreated:1,
                        isSBQ:"Y",                            
                        existMQ:true
                    })
                    }
                    else
                    {
                        this.setState({
                            lblMessage: "Subscription Updated",
                            lblMessageColor:"#009933",
                            isLoading:false,
                            isSBQ:"Y",                            
                            existMQ:true
                        })
                    }
                }
            }
    
            
           if (CheckInputChanges(propsEmail)) {
            sinkEmail.SubscriptionID=subscriptionId;
                if (sinkIdEmail != null) {
                    await UpdateSink(sinkIdEmail, sinkEmail);
                    if(this.state.IsMailChanged === true)
                    {                        
                        this.setState({
                            lblMessage: "Subscription Updated",
                            lblMessageColor:"#009933" ,
                            isLoading:false
                        })
                    }
                    
                } else {
                    var createdSinkIdEmail = await PostSink(sinkEmail);                
                    this.setState({sinkIdEmail: createdSinkIdEmail});                      
                    if(this.state.flagSubscriptionCreated ===1)   
                    {      
                    this.setState({
                        flagCreated:1,                        
                        isLoading:false,
                        isEmail:"Y",
                        existEmail:true 
                    })
                    }
                    else
                    {
                        this.setState({
                            lblMessage: "Subscription Updated",
                            lblMessageColor:"#009933" ,
                            isLoading:false,
                            isEmail:"Y",
                            existEmail:true 
                        })
                    }
                }
            }

        if((this.state.flagSubscriptionCreated===1) && (this.state.flagCreated===1))
        {  
            this.setState({               
                lblMessage: "Subscription Created",
                lblMessageColor:"#009933" ,
                isLoading:false,
                existingSubscription:true
            })

        }
    }
    }
    }

    async componentDidMount() {
        var subscriptionId = this.props.subscriptionId;

        if (subscriptionId != null) {
            var sinks = await GetSinksBySubscription(subscriptionId); 
            this.setState({
                existingSubscription:true})                
            if (sinks != null) {                                      
                for (var i = 0; i < sinks.length; i++) {
                    var sink = sinks[i];

                    if (sink.sinkType === 'C') {
                        this.setState({
                            existSink:true,
                            isAPI:"Y",
                            sinkIdAPI: sink.sinkID,
                            url: sink.callbackEndpoint,
                            username: sink.authNUid,
                            password: sink.authNPwd,
                            startDT_API: new Date(sink.startSubscriptionDT),
                            endDT_API: new Date(sink.endSubscriptionDT),
                            retryLimit_API: sink.retryLimit,
                            retryWait_API: sink.retryWait,
                            messageAge_API: sink.ttL_TimeSeconds,                            
                            jsonchecked_API:Boolean(Number(sink.jsonFormat))
                        });
                    } else if (sink.sinkType === 'Q') {
                        this.setState({
                            existMQ:true,
                            isSBQ:"Y",
                            sinkIdQueue: sink.sinkID,
                            queueName: sink.qName,
                            startDT_Queue: new Date(sink.startSubscriptionDT),
                            endDT_Queue: new Date(sink.endSubscriptionDT),
                            retryLimit_Queue: sink.retryLimit,
                            retryWait_Queue: sink.retryWait,
                            messageAge_Queue: sink.ttL_TimeSeconds,
                            jsonchecked_Queue:Boolean(Number(sink.jsonFormat))
                        });
                    } else if (sink.sinkType === 'E') {
                        this.setState({
                            existEmail:true,
                            isEmail:"Y",
                            sinkIdEmail: sink.sinkID,
                            email: sink.emailAddress,
                            startDT_Email: new Date(sink.startSubscriptionDT),
                            endDT_Email: new Date(sink.endSubscriptionDT),
                            retryLimit_Email: sink.retryLimit,
                            retryWait_Email: sink.retryWait,
                            messageAge_Email: sink.ttL_TimeSeconds,
                            jsonchecked_Email: Boolean(Number(sink.jsonFormat))                            
                        });                         
                    } 
                    this.setState({
                        loaded :true
                    });                          
            }
        }
    }  
    }
    sinkselectedindex(index){        
      this.setState({
        activeTab: index,
        lblMessage:null
      });
    }

    ShowEmailTab() {
        var orgids = configData.ShowEmailTab_byOrgID;
        if (orgids == '*') {
            return true;
        }
        else if (orgids == ' ' || orgids == '' || orgids == null) {
            return false;
        }
        var str_array = orgids.split(',');
        var boolShowEmailTab = false;
        for (var i = 0; i < str_array.length; i++) {
            if (str_array[i] == this.state.orgId) {
                boolShowEmailTab = true;
                break;
            }
        }
        return boolShowEmailTab;
    }

    render() {
        const {sinkIdAPI,lblMessage,lblMessageColor, url, username, password, startDT_API, endDT_API, retryLimit_API,existSink,isAPI, retryWait_API, messageAge_API,jsonchecked_API } = this.state;
        const {sinkIdQueue, queueName, startDT_Queue, endDT_Queue, retryLimit_Queue, existMQ,isSBQ,retryWait_Queue, messageAge_Queue,jsonchecked_Queue} = this.state;
        const {sinkIdEmail, email, startDT_Email, endDT_Email, retryLimit_Email, retryWait_Email,existEmail,isEmail, messageAge_Email,jsonchecked_Email } = this.state;
        const loaded=this.state;        
        const existingSubscription=this.state.existingSubscription;
        let delButton,approveButton,loaderbutton;     
        
        
       
        const formAPI =
        
            <Form>
                                 
                       <FormGroup>    
                        <Label className={this.state.bgcolorURL}>API URL </Label>                
                        <Row>
                        <Col sm={11}>
                            <Input value={(url === null) ? "" : url} onChange={u => this.setState({ url: u.target.value ,IsAPIChanged:true})} />
                        </Col>
                        <div className={existSink ? 'divshow' : 'divhide'}>
                        <AiFillDelete title="Delete Sink" color="#0e4161" size={40} 
                        onClick={(e) => { if (window.confirm('Do you want to delete this Sink?')) 
                        this.handleDeleteSink(sinkIdAPI,'C') }}></AiFillDelete>  
                        </div>                     
                        </Row>   
                        <div className="apiremarks">
                        Note: Endpoint must be behind Service Gateway of the same environment (Test, QA and Production)
                        </div>                    
                        </FormGroup>
                
                <Row>
                    <FormGroup>
                        <Label className={this.state.bgcolorUserName}>Username/Header</Label>
                        <Col sm={12}>
                            <Input value={(username === null) ? "" : username} onChange={u => this.setState({ username: u.target.value,IsAPIChanged:true })} />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <div className="password">


                        <Label className={this.state.bgcolorPassword}>Password/Subscription Key</Label>
                        <Col sm={13}>
                            <Input value={(password === null) ? "" : password} type="password" onChange={p => this.setState({ password: p.target.value,IsAPIChanged:true })} />
                        </Col>
                        </div>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup>
                        <Label className={this.state.bgcolorAPIStartDate}>Start Date</Label>
                        <div className="startdate">
                        <Col sm={8}>
                            <DateTimePicker calendarType="US" value={(startDT_API === null) ? "" : startDT_API} 
                            showLeadingZeros={true}
                            onChange={dt => this.setState({ startDT_API: dt,IsAPIChanged:true })} />
                        </Col>
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <div className="enddate">
                    <Col>
                        <Label className={this.state.bgcolorAPIEndDate}>End Date</Label></Col>
                        <Col sm={33} >
                            <DateTimePicker calendarType="US" firstDayOfWeek="4" showLeadingZeros={true}
                             value={(endDT_API === null) ? "" : endDT_API} onChange={dt => this.setState({ endDT_API: dt,IsAPIChanged:true })} />
                        </Col>
                        </div>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup>
                    <div className="function1">
                        <Label className={this.state.bgcolorretryLimitAPI}>Retry Limit</Label></div>
                        <Col sm={5}>
                            <Input value={(retryLimit_API === null) ? "" : retryLimit_API} style={{textAlign:"center"}} onChange={l => this.setState({ retryLimit_API: l.target.value,IsAPIChanged:true })} />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                    <div className="function2">
                        <Label className={this.state.bgcolorretryWaitAPI}>Retry Wait</Label></div>
                        <Col sm={7}>
                            <Input value={(retryWait_API === null) ? "" : retryWait_API} style={{textAlign:"center"}} onChange={w => this.setState({ retryWait_API: w.target.value,IsAPIChanged:true})} />
                        </Col>
                        <div className="my1">
                           Seconds
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <div className="function3">
                        <Label className={this.state.bgcolormessageAgeAPI}>Message Age</Label></div>
                        <Col sm={6}>
                            <Input value={(messageAge_API === null) ? "" : messageAge_API} style={{textAlign:"center"}} onChange={m => this.setState({ messageAge_API: m.target.value,IsAPIChanged:true })} />
                        </Col>
                        <div className="my5">
                           Seconds
                        </div>
                    </FormGroup>
                    <FormGroup> 
                            <div className="apijson">                           
                        <Col sm={3}> 
                        <Label className="chkjsonlabel">JSON Format</Label>   </Col>                   
                        <Col sm={45}>                        
                        <Input type="checkbox" className="chkStyle"
                        onChange={e => this.setState({ jsonchecked_API: !jsonchecked_API,IsAPIChanged:true })}
                         checked={jsonchecked_API} />
                        </Col>
                        </div>
                    </FormGroup>
                </Row>
            </Form>;

        const formQueue =
            <Form>               
                <FormGroup>                            
                        <Label className={this.state.bgcolorURL}>Queue Name</Label>            
                        <Row>
                        <Col sm={11}>
                        <Input value={(queueName === null) ? "" : queueName} onChange={q => this.setState({ queueName: q.target.value,IsQueueChanged:true })} />
                        </Col>

                        <div className={this.state.existMQ ? 'divshow' : 'divhide'}>
                        <AiFillDelete title="Delete Sink" color="#0e4161" size={40} 
                        onClick={(e) => { if (window.confirm('Do you want to delete this Sink?')) 
                        this.handleDeleteSink(sinkIdQueue,'Q') }}></AiFillDelete>      
                        </div>
                                         
                        </Row>                       
                </FormGroup>

                <Row>
                    <FormGroup>
                    <div className="startdate1">
                        <Label className={this.state.bgcolorQueueStartDate}>Start Date</Label>
                        <Col sm={21}>
                            <DateTimePicker showLeadingZeros={true} calendarType="US" value={(startDT_Queue === null) ? "" : startDT_Queue} onChange={dt => this.setState({ startDT_Queue: dt,IsQueueChanged:true })} />
                        </Col></div>
                    </FormGroup>
                    <FormGroup>
                    <div className="enddate1">
                        <Label className={this.state.bgcolorQueueEndDate}>End Date</Label>
                        <Col sm={35}>
                            <DateTimePicker showLeadingZeros={true} calendarType="US" value={(endDT_Queue === null) ? "" : endDT_Queue}  onChange={dt => this.setState({ endDT_Queue: dt,IsQueueChanged:true })} />
                        </Col>
                        </div>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup>
                    <div className="function4">
                        <Label className={this.state.bgcolorretryLimitQueue}>Retry Limit</Label></div>
                        <Col sm={5}>
                            <Input value={(retryLimit_Queue === null) ? "" : retryLimit_Queue} style={{textAlign:"center"}} onChange={l => this.setState({ retryLimit_Queue: l.target.value,IsQueueChanged:true })} />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                    <div className="function5">
                        <Label className={this.state.bgcolorretryWaitQueue}>Retry Wait</Label></div>
                        <Col sm={7}>
                            <Input value={(retryWait_Queue === null) ? "" : retryWait_Queue} style={{textAlign:"center"}} onChange={w => this.setState({ retryWait_Queue: w.target.value,IsQueueChanged:true })} />
                        </Col>
                        <div className="my1">
                           Seconds
                        </div>
                    </FormGroup>
                    <FormGroup>
                    <div className="function6">
                        <Label className={this.state.bgcolormessageAgeQueue}>Message Age</Label></div>
                        <Col sm={23}>
                            <Input value={(messageAge_Queue === null) ? "" : messageAge_Queue} style={{textAlign:"center"}} onChange={m => this.setState({ messageAge_Queue: m.target.value,IsQueueChanged:true })} />
                        </Col>
                        <div className="my3">
                           Seconds
                        </div>
                    </FormGroup>
                    <FormGroup> 
                            <div className="servicebusjson">

                            
                        <Col sm={3}> 
                        <Label>JSON Format</Label>   </Col>                   
                        <Col sm={45}>                        
                        <Input type="checkbox" onChange={e => this.setState({ jsonchecked_Queue: !jsonchecked_Queue,IsQueueChanged:true })}
                         checked={jsonchecked_Queue} />
                        </Col>
                        </div>
                    </FormGroup>
                </Row>
            </Form>;

        const formEmail =
            <Form>           
                  <FormGroup>    
                    <Label className={this.state.bgcolorEmailName}>Email Address</Label>
              
                    <Row>
                    <Col sm={11}>
                    <Input value={(email === null) ? "" : email} onChange={e => this.setState({ email: e.target.value,IsMailChanged:true })} />                            
                    </Col>

                    <div className={this.state.existEmail ? 'divshow' : 'divhide'}>
                    <AiFillDelete title="Delete Sink" color="#0e4161" size={40} 
                     onClick={(e) => { if (window.confirm('Do you want to delete this Sink?')) 
                     this.handleDeleteSink(sinkIdEmail,'E') }}></AiFillDelete>
                     </div>
                    </Row>
                </FormGroup>

                <Row id="endd">
                    <FormGroup>
                    <div className="startdate1">
                        <Label className={this.state.bgcolorEmailStartDate}>Start Date</Label>
                        <Col sm={24}>

                            <DateTimePicker showLeadingZeros={true} calendarType="US" value={(startDT_Email === null) ? "" : startDT_Email} onChange={dt => this.setState({ startDT_Email: dt,IsMailChanged:true })} />
                        </Col></div>
                    </FormGroup>
                    <FormGroup>
                    <div className="enddate1">
                        <Label className={this.state.bgcolorEmailEndDate}>End Date</Label>
                        <Col sm={22}>
                            <DateTimePicker showLeadingZeros={true} calendarType="US" value={(endDT_Email === null) ? "" : endDT_Email} onChange={dt => this.setState({ endDT_Email: dt,IsMailChanged:true })} />
                        </Col>
                        </div>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup>
                    <div className="function7">
                        <Label className={this.state.bgcolorretryLimitEmail}>Retry Limit</Label></div>
                        <Col sm={5}>
                            <Input value={(retryLimit_Email === null) ? "" : retryLimit_Email} style={{textAlign:"center"}} onChange={l => this.setState({ retryLimit_Email: l.target.value,IsMailChanged:true })} />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                    <div className="function8">
                        <Label className={this.state.bgcolorretryWaitEmail}>Retry Wait</Label></div>
                        <Col sm={7}>
                            <Input value={(retryWait_Email === null) ? "" : retryWait_Email} style={{textAlign:"center"}} onChange={w => this.setState({ retryWait_Email: w.target.value,IsMailChanged:true })} />
                        </Col>
                        <div className="my1">
                           Seconds
                        </div>
                    </FormGroup>
                    <FormGroup>
                    <div className="function9">
                        <Label className={this.state.bgcolormessageAgeEmail}>Message Age</Label></div>
                        <Col sm={63}>
                            <Input value={(messageAge_Email === null) ? "" : messageAge_Email} style={{textAlign:"center"}} onChange={m => this.setState({ messageAge_Email: m.target.value,IsMailChanged:true })} />
                        </Col>
                        <div className="my2">
                           Seconds
                        </div>
                    </FormGroup>
                    <FormGroup> 
                            <div className="emailjson">

                            
                        <Col sm={3}> 
                        <Label>JSON Format</Label>   </Col>              
                        <Col sm={45}>                        
                        <Input type="checkbox" onChange={e => this.setState({ jsonchecked_Email: !jsonchecked_Email,IsMailChanged:true })}
                         checked={jsonchecked_Email} />
                        </Col>
                        </div>
                    </FormGroup>
                </Row>
            </Form>;
        if(existingSubscription)
        {
                delButton= <button className="SinksForm-delete-button" 
                onClick={(e) => { if (window.confirm('Are you sure you wish to delete this Subscription with all its Sinks and Delivery history?')) this.handleDelete(e) }}>Delete</button>        
                
                
        }
        if(this.props.subscription.approvalStatus ==="Pending")
        {
            approveButton=<button class="tablinks" id="AppButton" onClick={this.handleApproved}>Request Approval</button>
                
                
        }

        if(this.state.isLoading)
        {
            loaderbutton=<Loader type="ThreeDots" color="#00BFFF" height={50} width={50}  timeout={3000}/> 
        }

        const cancelButton = <button className="SinksForm-cancel-button" onClick={this.handleCancel}>Cancel</button>
        const saveButton = 
                <button className="SinksForm-save-button"  
                disabled={!(this.props.subscription.IsChanged  || this.state.IsAPIChanged || this.state.IsQueueChanged || this.state.IsMailChanged)} 
                onClick={this.handleSave}>Save</button>
       
        if(loaded)
        {            
            return (

                <div className="SinksForm">
                     <div className="SinksForm-loaderstyle"> { loaderbutton } </div>

                    <div className="messagelabel"><font color ={lblMessageColor}> {lblMessage}  </font></div>
                    <div className="approver">Approval Status:  
                    &nbsp;
                    <label>
                    {(!this.state.existingSubscription)?"":(this.props.subscription.approvalStatus==="")?(((this.props.subscription.approverMail!=="") && (this.props.subscription.approverMail!=null))?"Pending":"Approved"):(this.props.subscription.approvalStatus)}
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {approveButton}
                    
                    </div>
                    <div className="approver">Approver:
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                    <label>{(this.props.subscription.approverMail!=="")?this.props.subscription.approverMail:"Self-service"}</label></div>
                    <div className="SinksForm-title">Delivery Mechanism </div>                    
                    <Tabs className="SinksForm-inputs">
                        <TabList className="SinksForm-paneltab">
                        

                                                                     
                        
<Tab onClick={() =>this.sinkselectedindex(0)}
 className={((this.state.activeTab === 0)&& existSink && existingSubscription && (isAPI==='Y')) ? 'apiselect-active':
 ((this.state.activeTab !== 0)&& existSink && existingSubscription && (isAPI==='Y')) ? 'apiunselect-active' :
 ((this.state.activeTab === 0)) ? 'apiselect-inactive' : 'apiunselect-inactive'} >API
                        </Tab>

<Tab onClick={() =>this.sinkselectedindex(1)}
 className={((this.state.activeTab === 1) && existMQ && existingSubscription && (isSBQ==='Y')) ? 'queueselect-active':
 ((this.state.activeTab !== 1)&& existMQ && existingSubscription && (isSBQ==='Y')) ? 'queueunselect-active' :
 (((this.state.activeTab === 1)) ) ? 'queueselect-inactive' : 'queueunselect-inactive'} >Service Bus Queue
                        </Tab>
{
    (this.ShowEmailTab()) && (
<Tab onClick={() =>this.sinkselectedindex(2)}
 className={((this.state.activeTab === 2)&& existEmail && existingSubscription && (isEmail==='Y')) ? 'emailselect-active':
 ((this.state.activeTab !== 2)&& existEmail && existingSubscription && (isEmail==='Y')) ? 'emailunselect-active' :
 ((this.state.activeTab === 2)) ? 'emailselect-inactive' : 'emailunselect-inactive'} >Email
                        </Tab>)
}                      
                      
                        </TabList>

                        <TabPanel>{formAPI}</TabPanel>
                        <TabPanel>{formQueue}</TabPanel>
                        {
                            (this.ShowEmailTab()) && (
                            <TabPanel>{formEmail}</TabPanel>)
                        }
                    </Tabs>
                    {delButton}                
                    {cancelButton}{saveButton}                
                </div>
            );
        }
        else {
            return (<div>
                <div className="Header-loading">
                <Loader type="ThreeDots" color="#00BFFF" height={50} width={50}  timeout={3000}/>                 
                </div>
            </div>);
        }
    }
}

export default withRouter(SinksForm);