import axios from "axios";
import configData from "./../../../config.json";

const baseUri = configData.APIURL;
const apikey = configData.APIKEY;


export default async (approverEmailAddress) => {
    try {
        
        const result = await axios({
           method: "get",
            headers: { 'Ocp-Apim-Subscription-Key': apikey,
                       'Access-Control-Allow-Origin' : '*' },
            url: baseUri + `/authorize/subscriptions?approverEmailAddress=${approverEmailAddress}`
        });        
        return result.data.authorisations;
    } catch (error) {
        return null;
    }
}
