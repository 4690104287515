import axios from "axios";
import configData from "./../../../config.json";

const baseUri = configData.APIURL;
const apikey = configData.APIKEY;


export default async (eventId) => {
    try {        
        const result = await axios({
           method: "get",
           headers: { 'Ocp-Apim-Subscription-Key': apikey,
           'Access-Control-Allow-Origin' : '*' },
            url: baseUri + `/clientData/${eventId}`
        });      
     return result.data;     
        
    } catch (error) {
        return null;
    }
}