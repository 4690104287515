import React, { Component } from "react";
import { Form, FormGroup, Label, Input, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from "react-select";
import Loader from 'react-loader-spinner';
import GetSubscription from '../Services/GetSubscription.js';
import GetEventCategory from '../Services/GetEventCategory.js';
import SinksForm from '../SinksComponents/SinksForm.js';
import '../../../sass/header.scss';
import '../../../sass/subscriptionform.scss';
import '../../../sass/sinksform.scss';
import  AuthContext  from " ../../../src/Auth";

class SubscriptionForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orgId:this.props.orgId,
            newSubscription:true,
            subscriptionId: this.props.subscriptionId,            
            loginSubscriberId: AuthContext.loginSubscriberId,
            isNewSubscription: this.props.isNewSubscription,
            existingSubscription: false,
            approvalRequired:false,
            approverMail:this.props.approverMail,
            subscriptionheader:null,
            subscriptionName: null,
            purpose: null,
            contactEmail: null,
            approved:null,
            events: this.props.events,
            eventCategoryId: this.props.eventCategoryId,
            topicsOfInterest: [],
            isChanged:false,                       
            selectedOption:[],
            multiValue: [], 
            backgroundcolor:null,
            bgcolorName:"message",
            bgcolorPurpose:"message",
            bgcolorEvents:"message",
            bgcolorContact:"message",
            styleName:null,
            stylePurpose:null,
            styleEvents:null,
            styleContact:null,            
        } 
        
        this.handleChangeCategory = this.handleChangeCategory.bind(this);        
        this.handleMultiChange=this.handleMultiChange.bind(this);
        this.setBackgroundColor= this.setBackgroundColor.bind(this);        
    }

    isExistingSubscription = async () => {
        var subscriptionId = this.state.subscriptionId;

        if (subscriptionId != null) {
            const subscription = await GetSubscription(subscriptionId);
            const eventCategory = await GetEventCategory(subscription.eventCategoryID);

            this.setState({
                existingSubscription: true,
                subscriptionName: subscription.subscriptionName,
                subscriptionheader:"Edit Subscriptions",                
                purpose: subscription.purpose,
                contactEmail: subscription.contactEmail,
                eventCategoryId: subscription.eventCategoryID,
                approverMail:eventCategory.approverEmailAddress,
                approved:subscription.approved,
                topicsOfInterest: subscription.topicsOfInterest.split(","),                
                events: eventCategory.events,
                isChanged:false,                                
                multiValue: subscription.topicsOfInterest.split(",").map(d => ({ label: d,value: d }))                         
            });            
        }
        else
        {
            this.setState ({subscriptionheader:"New Subscriptions"});
        }

        for (var i = 0; i < this.state.topicsOfInterest.length; i++) {
            var ev=this.state.events.replace((this.state.topicsOfInterest[i])+',','')       
            this.setState({
                events:ev
            })            
        } 
    }

    handleMultiChange(option) {
      
        this.setState(state => {
          return {
            multiValue: option,
            isChanged:true 
          };
        });
      }

    handleChangeCategory = async e => {
        var eventCategoryId = e.target.value;

        const eventCategory = await GetEventCategory(eventCategoryId);

        this.setState({
            eventCategoryId: eventCategoryId,
            events: eventCategory.events,
            approverMail:eventCategory.approverEmailAddress,
            multiValue: [],
            isChanged:true 
        });
    }

     

    setBackgroundColor (colorvalue) {       

        if(colorvalue.indexOf("Subscription")>=0)            
            this.setState({ bgcolorName : colorvalue,bgcolorContact: "validatedmessage",
                bgcolorPurpose:"validatedmessage", bgcolorEvents:"validatedmessage"});
        else if(colorvalue.indexOf("Purpose")>=0)            
            this.setState({ bgcolorPurpose : colorvalue,bgcolorName:"validatedmessage",bgcolorEvents:"validatedmessage",
            bgcolorContact:"validatedmessage"});
        else if(colorvalue.indexOf("Event")>=0)            
            this.setState({ bgcolorEvents : colorvalue,bgcolorName:"validatedmessage",bgcolorPurpose:"validatedmessage",
            bgcolorContact:"validatedmessage"});
        else if((colorvalue.indexOf("Contact")>=0) || (colorvalue.indexOf("Email")>=0))
            this.setState({ bgcolorContact : colorvalue, bgcolorName:"validatedmessage"
                            ,bgcolorPurpose:"validatedmessage", bgcolorEvents:"validatedmessage"});     
        else
        {            
            this.setState({ bgcolorName : colorvalue,
                            bgcolorPurpose : colorvalue,
                            bgcolorEvents : colorvalue,
                            bgcolorContact : colorvalue,
                            styleName : "ValidateLabel",
                            stylePurpose : "ValidateLabel",
                            styleEvents : "ValidateLabel",
                            styleContact : "ValidateLabel"
                            });   
        }       
      }

    
 
    componentDidMount() {
        this.isExistingSubscription();
    }
    logOut() {
        AuthContext.logOut();
        AuthContext.clearCache();
    }
    
    render() {
        const {backgroundcolor,isNewSubscription,subscriptionId, existingSubscription,loginSubscriberId, subscriptionName,approved, purpose, eventCategoryId, events, contactEmail,orgId,isChanged, approverMail } = this.state;        

        var setBackgroundColor=this.setBackgroundColor;        

        var topic='';
        if(this.state.multiValue.length>0)
        {
            for (var i = 0; i < this.state.multiValue.length; i++) {
                topic= i===0? this.state.multiValue[i]['label']: topic + ','+ this.state.multiValue[i]['label'];               
            }
        }

        const subscriptionDetails = {
            OrgID: this.props.orgId,
            SubscriberID: loginSubscriberId,
            SubscriptionName: subscriptionName,
            ContactEmail: contactEmail,
            EventCategoryID: eventCategoryId,
            TopicsOfInterest: topic,
            Purpose: purpose,
            IsChanged:isChanged,
            approverMail:approverMail,
            subscriptionapproved:approved,
            Approved:(((typeof(subscriptionId))===undefined) || ((typeof(subscriptionId))==='undefined')) ? ((approverMail!=="" && approverMail!=null) ?0:1) :approved,     
            approvalStatus: ((approverMail!=="") && (approved===0))?"Pending":(existingSubscription)?"Approved":""            
        };
               
        const subscriptionForm =
            <Form>
                <FormGroup>
                <div className="function">
                    <Label className={this.state.bgcolorName}>Name<font color="red">*</font></Label></div>

                    <Col sm={13}>
                <Input value={(subscriptionName==null)?"":subscriptionName}                
                 onChange={s => this.setState({ subscriptionName: s.target.value,isChanged:true })} />

                    </Col>
                </FormGroup>
                <FormGroup>
                    <div className="function"  >
                    <Label className={this.state.bgcolorPurpose}>Purpose<font color="red">*</font></Label>
                    </div>
                    <Col sm={13}>
                        <Input type="textarea" ref={this.textInput}  
                         value={(purpose==null)?"":purpose} onChange={p => this.setState({ purpose: p.target.value,isChanged:true })} />
                    </Col> 
                </FormGroup>
                <FormGroup>
                    <div className="function">
                    <Label>Category<font color="red">*</font></Label></div>
                    <Col sm={13}>
                        <Input type="select" value={eventCategoryId} onChange={this.handleChangeCategory}>
                            {this.props.eventCategories.map((event) => (
                                <option key={event.eventCategoryID} value={event.eventCategoryID}>{event.eventName}</option>
                            ))}
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup>
                <div className="function">
                    <Label className={this.state.bgcolorEvents}>Events<font color="red">*</font></Label></div>
                    <Col sm={13}>                       

                        <Select isMulti                                                         
                        options={events.split(",").map(e => ({ label: e, value: e}))}                        
                        value={this.state.multiValue}
                        
                        onChange={this.handleMultiChange}                        
                        closeMenuOnSelect={false}                                                                      
                         />
                        
                              
                    </Col>
                </FormGroup>
                <FormGroup>
                <div className="function">
                    <Label className={this.state.bgcolorContact}>Contact Email<font color="red">*</font></Label></div>
                    <Col sm={13}>
                        <Input value={(contactEmail==null)?"":contactEmail} 
                                                 onChange={e => this.setState({ contactEmail: e.target.value,isChanged:true })} />
                    </Col>
                </FormGroup>   
            </Form>;

        const newheaderlink=
        <div className="SubscriptionForm-headerlink">
        <Link to={{
         pathname: '/Subscriptions',
         state: {    orgId: this.state.orgId,
                     subscriptionId: this.state.subscriptionId,
                     loginSubscriberId:this.state.loginSubscriberId
                     }
         }}>Subscriptions </Link> {">"} New Subscriptions  </div>;

         const editheaderlink=
        <div className="SubscriptionForm-headerlink">
        <Link to={{
         pathname: '/Subscriptions',
         state: {    orgId: this.state.orgId,
                     subscriptionId: this.state.subscriptionId,
                     loginSubscriberId:this.state.loginSubscriberId }
         }}>Subscriptions </Link> {">"} Edit Subscriptions  </div>;
         

const logOut =
<button type="button" className="btn btn-link" onClick={this.logOut}>Log Out </button>

const header = <div className="Header">
            <div className="Header-banner">
                <div className="Header-corner_img"></div></div>
            <div className="Header-title">
                Event Bus Broker
            </div>          
<p className="Header-welcome">
    Hello {AuthContext.getCachedUser().profile.name}.
    </p>
            <div className="Header-about"> <Link to={{
                    pathname: '/About',
                    state: {    orgId: 1 }}}
                target="_About" rel='noopener noreferrer'>
               About
            </Link> &nbsp;&nbsp;&nbsp;&nbsp;  <br></br><br></br><br></br>  {logOut}      
                    </div>
                  </div>;
                

        const formComponents =
            <div className="SubscriptionForm">                 
                { <div className="SubscriptionForm-title">
                    {this.state.subscriptionheader}</div> }
                { <div className = {(existingSubscription)?"SubscriptionForm-editmaindiv":"SubscriptionForm-newmaindiv"}>
                {subscriptionForm}</div> }                    
                
            </div>;

        if (existingSubscription) {
            return (
                <React.Fragment>
            <div>
               <div width="50%">{editheaderlink}{formComponents}</div>
               <div width="50%">
                <SinksForm subscriptionId={subscriptionId} lgv={backgroundcolor} subscription={subscriptionDetails
                } orgId={orgId} loginSubscriberId={loginSubscriberId} setBackgroundColor={setBackgroundColor.bind(this)} />
                </div>
            </div>          
            </React.Fragment>
            );
        } else if(isNewSubscription) {
            return (
                <React.Fragment><div>
                    {header}
                </div>                
                 <div>
                 <div width="50%">{newheaderlink}{formComponents}</div>
                 <div width="50%">              
                <SinksForm subscriptionId={subscriptionId} subscription={subscriptionDetails} 
                setBackgroundColor={setBackgroundColor.bind(this)}
                 orgId={orgId} />
                </div>                
            </div>
            </React.Fragment>);
        }
        else {
            return (<div>
                <div className="Header-loading">
                    <Loader type="ThreeDots" color="#00BFFF" height={100} width={100}  timeout={3000}/>                     
                </div>
            </div>);
        }
    }
}

export default SubscriptionForm;
