import React, { Component } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { Link, Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import AllSubscribersTable from "./AllSubscribersTable";
import AllSubscriptionsTable from "./AllSubscriptionsTable";
import AuthContext from "../../Auth.js";
import GetAllSubscribers from "./Services/GetAllSubscribers.js";
import GetAllSubscriptions from "./Services/GetAllSubscriptions.js";
import GetOrganizations from "./../OrganizationsManagement/Services/GetOrganizations.js";
import "../../sass/header.scss";
import "../../sass/adminpage.scss";

class AdminPage extends Component {
  constructor(props) {
    super(props);

    if (this.props.location.state == null && localStorage.orgId == null) {
      this.logOut();
    } else if (localStorage.orgId == null) {
      localStorage.orgId = this.props.location.state.orgId;
      localStorage.loginSubscriberId =
        this.props.location.state.loginSubscriberId;
    }

    this.state = {
      orgId: localStorage.orgId, //this.props.location.state.orgId,
      loginSubscriberId: localStorage.loginSubscriberId, //this.props.location.state.loginSubscriberId,
      subscribers: [],
      subscriptions: [],
      isAdminPageLoaded: false,
      activeTab: 0,
    };
    this.handleBackToMainPage = this.handleBackToMainPage.bind(this);
  }

  handleBackToMainPage = (s) => {
    s.preventDefault();
    this.props.history.push({
      pathname: "/Subscriptions",
      state: {
        orgId: this.state.orgId,
        loginSubscriberId: this.state.loginSubscriberId,
      },
    });
  };

  async componentDidMount() {
    const subscribersObject = await GetAllSubscribers();
    const subscriptionsObject = await GetAllSubscriptions();
    if (subscribersObject != null) {
      const organizationsObject = await GetOrganizations();
      if (organizationsObject != null) {
        let orgDict = {};
        organizationsObject.forEach((organization) => {
          orgDict[organization.orgID] = organization.description;
        });

        subscribersObject.forEach((subscriber) => {
          subscriber.orgName = orgDict[subscriber.orgID];
        });

        if (subscriptionsObject != null) {
          subscriptionsObject.forEach((subscription) => {
            subscription.orgName = orgDict[subscription.orgID];
            subscription.subscriberName = null;
            for (let i = 0; i < subscribersObject.length; i++) {
              if (subscription.subscriberID === subscribersObject[i].subscriberID) {
                subscription.subscriberName = subscribersObject[i].name;
                break;
              }
            }
          });
        }
      }
      this.setState({
        subscribers: subscribersObject,
        subscriptions: subscriptionsObject,
        isAdminPageLoaded: true,
      });
    }
  }

  logOut() {
    AuthContext.logOut();
    AuthContext.clearCache();
  }

  getSelectedIndex(index) {
    this.setState({
      activeTab: index,
    });
  }

  render() {
    const {
      orgId,
      subscribers,
      subscriptions,
      loginSubscriberId,
      isAdminPageLoaded,
    } = this.state;

    if (orgId !== "1") {
      return <Redirect to="/" />;
    }

    const backToMainPageButton = (
      <button className="btn btn-link" onClick={this.handleBackToMainPage}>
        Back to Home Page
      </button>
    );

    const logOut = (
      <button type="button" className="btn btn-link" onClick={this.logOut}>
        Log Out{" "}
      </button>
    );

    const adminHeader = (
      <div className="Header">
        <div className="Header-banner">
          <div className="Header-corner_img"></div>
        </div>
        <div className="Header-title">Event Bus Broker</div>
        <p className="Header-welcome">
          Hello {AuthContext.getCachedUser().profile.name}.
        </p>
        <div className="Header-adminabout">
          <Link
            to={{
              pathname: "/About",
              state: { orgId: 1 },
            }}
            target="_About"
            rel="noopener noreferrer"
          >
            About
          </Link>
          <br></br>
          <br></br>
          <br></br>
          {backToMainPageButton}
          {logOut}
          <br></br>
        </div>
      </div>
    );

    if (isAdminPageLoaded && orgId === "1") {
      return (
        <React.Fragment>
          <div>{adminHeader}</div>
          <Tabs>
            <TabList className="SubscriptionForm-paneltab">
              <Tab
                onClick={() => this.getSelectedIndex(0)}
                className={
                  this.state.activeTab === 0
                    ? "SubscriptionForm-subselect-active"
                    : "SubscriptionForm-subunselect-active"
                }
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Manage
                Subscribers
              </Tab>

              <Tab
                onClick={() => this.getSelectedIndex(1)}
                className={
                  this.state.activeTab === 1
                    ? "SubscriptionForm-subselect-active"
                    : "SubscriptionForm-subunselect-active"
                }
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Manage
                Subscriptions
              </Tab>
            </TabList>

            <TabPanel>
              <AllSubscribersTable
                data={subscribers}
                orgId={orgId}
                loginSubscriberId={loginSubscriberId}
              />
            </TabPanel>

            <TabPanel>
              <AllSubscriptionsTable
                data={subscriptions}
                orgId={orgId}
                loginSubscriberId={loginSubscriberId}
              />
            </TabPanel>
          </Tabs>
        </React.Fragment>
      );
    } else {
      return (
        <div>
          {adminHeader}
          <div className="Header-loading">
            <Loader
              type="ThreeDots"
              color="#00BFFF"
              height={100}
              width={100}
              timeout={3000}
            />
          </div>
        </div>
      );
    }
  }
}

export default AdminPage;
