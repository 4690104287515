import axios from "axios";
import configData from "./../../../config.json";

const baseUri = configData.APIURL;
const apikey = configData.APIKEY;


export default async (sinkId, sink) => {
    try {
        await axios({
            method: 'put',
            url: baseUri + `/sinks/${sinkId}`,
            data: sink,
            headers: {
               'Content-Type': 'application/json',
               'Ocp-Apim-Subscription-Key': apikey,
                'Access-Control-Allow-Origin' : '*' 
            }
        });
    } catch (error) {
        return;
    }
}