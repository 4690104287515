export default (props,propsSource) => {  
    var sinkType;
    if(propsSource==='API')  
        sinkType='API Sink: '
    else if(propsSource==='Queue')  
        sinkType='Service Bus Sink: '
    else if(propsSource==='email')  
        sinkType='Email Sink: '

        
    if (props[0] !== null && props[0] !== '')
    {
    if ((props[1] === null || props[1] === '') && (propsSource==='API')) {
        return (sinkType + "Username is required")        
    }
    else if ((props[2] === null || props[2] === '') && (propsSource==='API')  ) {
        return (sinkType + "Password is required")        
    }
    else if (props[3] === null || props[3] === '') {
        return (sinkType + "Start Date is required")        
    }
    else if (props[4] === null || props[4] === '') {
        return (sinkType + "End Date is required")        
    }
    else if (props[5] === null || props[5] === '') {
        return (sinkType + "Retry Limit is required")        
    }
    else if (props[6] === null || props[6] === '') {
        return (sinkType + "Retry Wait is required")        
    }
    else if (props[7] === null || props[7] === '') {
        return (sinkType + "Message Age is required")        
    }
    else if((!props[0].startsWith("https://") && (propsSource==='API')))
    {
        return "API URL must be over SSL (https://)";        
    }
    else
        return true;
}
else
return true;
}
    