import React, { Component } from "react";
import { withRouter } from 'react-router';
import GetClientDataPayload from './Services/GetClientDataPayload.js';
import '../../sass/clientdata.scss';
import  AuthContext  from '../../Auth.js';
import { Link } from 'react-router-dom';

class ClientDataReports extends Component {
    constructor(props) {
        super(props);
        this.state = {                        
            eventID:this.props.location.state.eventID,
            orgId:this.props.location.state.orgId

        };
        this.handleclose = this.handleclose.bind(this);
    }   

    
    async componentDidMount() {
        var eventID = this.state.eventID;
        if (eventID != null) {
            const clientdata = await GetClientDataPayload(eventID);              
            this.setState({
                clientDataPayload: clientdata.clientDataPayload
            });
        }  
    }

    handleclose= s => {
        s.preventDefault();
        this.props.history.push({
            pathname: "/Subscriptions",
            state: {
                orgId: this.state.orgId                
            }
        });
       
    }

    // handleClose= s => {
    //     s.preventDefault();        
    //     window.open("about:blank", "_self");
    //     window.close();        
    // }
    logOut() {
        AuthContext.logOut();
        AuthContext.clearCache();
    }
    render() {
        const {clientDataPayload}=this.state;
        
        const logOut =
        <button type="button" class="btn btn-link" onClick={this.logOut}>Log Out </button>

        const backbutton =
        <button className="ClientDataReports-cancel-button" onClick={this.handleclose}>Close</button>
        
    const header = <div className="Header">
        <div className="Header-banner">
            <div className="Header-corner_img"></div>
        </div>
        <div className="Header-title">
            Event Bus Broker
            </div>
        <p className="Header-welcome">
            Hello {AuthContext.getCachedUser().profile.name}.
            </p>
        <div className="Header-about">
        <Link to={{
                pathname: '/About',
                state: {    orgId: 1 }}}
            target="_About" rel='noopener noreferrer'>
           About
        </Link>
         <br></br><br></br><br></br> 
        {logOut}<br></br>
                 </div>
                 
    
        
        
    </div>

        const reportHeader=
            <div className="ClientDataReports-title">Client Data</div>;                          
        
       return( <div>{header}{reportHeader}
            {backbutton}
            <div className="ClientDataReports-Panel">
            <pre className="ClientDataReports-Panel-text">
            {clientDataPayload}</pre>             
            </div>
        </div>);
   
   }
}
export default withRouter(ClientDataReports);