import React from "react";
import { Switch, BrowserRouter, Route } from 'react-router-dom';
import SelectOrganization from './components/OrganizationsManagement/SelectOrganization';
import DisplaySubscriptions from './components/SubscriptionsManagement/CurrentSubscriptions/DisplaySubscriptions';
import ManageSubscription from './components/SubscriptionsManagement/SubscriptionComponents/ManageSubscription';
import NewSubscription from './components/SubscriptionsManagement/SubscriptionComponents/NewSubscription';
import ClientDataReports from './components/ReportsManagement/ClientDataReports';
import ViewChanges from './components/SubscriptionsManagement/CurrentSubscriptions/ViewSubscriptionChanges';
import AboutPage from './components/SubscriptionsManagement/SubscriptionComponents/AboutPage';
import Authorize  from './components/SubscriptionsManagement/CurrentSubscriptions/Authorize';
import Page404 from './components/GlobalManagement/NotFoundPage';
import AdminPage from './components/AdminPage/AdminPage';
import AdminManageSubscriber from './components/AdminPage/AdminManagementComponents/AdminManageSubscriber';
import AdminManageSubscription from './components/AdminPage/AdminManagementComponents/AdminManageSubscription';


const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path='/' component={SelectOrganization} />
            <Route exact path='/null' component={SelectOrganization} />
            <Route path='/Subscriptions' component={DisplaySubscriptions} />
            <Route path='/AddSubscription' component={NewSubscription} />
            <Route path='/EditSubscription' component={ManageSubscription} />
            <Route exact path="/ClientData" component={ClientDataReports} />
            <Route path="/ViewChanges" component={ViewChanges}/>
            <Route path="/About" component={AboutPage} />    
            <Route path="/AdminPage" component={AdminPage} />
            <Route path="/AdminManageSubscriber" component={AdminManageSubscriber} />
            <Route path="/AdminManageSubscription" component={AdminManageSubscription} />
            <Route exact path='/Authorize' component={Authorize} />
            <Route component={Page404} />
        </Switch>
    </BrowserRouter>
)

export default Routes;

 
