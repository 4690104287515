import React, { Component } from "react";
//import "react-web-tabs/dist/react-web-tabs.css";
import "react-tabs/style/react-tabs.css";
import DisplayReports from './DisplayReports.js';
import DateTimePicker from 'react-datetime-picker';
import '../../sass/header.scss';
import '../../sass/reports.scss';
import ValidateDate from '../GlobalServices/ValidateDate.js';
import addDays from 'date-fns/addDays'
import moment from "moment";
import  AuthContext  from '../../Auth.js';
import '../../sass/subscriptionform.scss';


class DeliveredEvents extends Component {   

    constructor(props) {
    super(props);
    let currentDate = new Date();
    let defaultstartDate= new Date();
    defaultstartDate.setHours(defaultstartDate.getHours()-2,defaultstartDate.getMinutes(),0,0);
    this.state = { 
        loginSubscriberId: AuthContext.loginSubscriberId,
        orgId:this.props.orgId,
        subscriptionId: this.props.subscriptionId,                           
        submitted: false,
        startDT: defaultstartDate,
        endDT: currentDate,           
        date:null,                    
        labelerrormessage:null,
        selectedReport: 'events'                  
    };    
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);        
    this.handleSubmit = this.handleSubmit.bind(this);   
}
formatDT = (dt) => {
    return moment(dt).format('YYYY-MM-DDTHH:mm:ss');
}

 
handleChangeStart = (date) => {
    this.setState({
        startDT: date,    
        endDT:''        ,
        submitted: false
    });        
}

handleChangeEnd = (date) => {
    this.setState({
        endDT: date,
        submitted: false
    });
}



handleSubmit = o => {
    o.preventDefault();
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);       
    if((this.state.startDT== null))
    {
        this.setState({
            labelerrormessage:'Please select Start Date',
            submitted: false
        });
    }
    else if(this.state.endDT==null)
    {
        this.setState({
            labelerrormessage:'Please select End Date',
            submitted: false
        });
    }
            
    if(ValidateDate(this.state.startDT,this.state.endDT)!==true) {    
            this.setState({
                labelerrormessage:'Start Date is greater than End Date',
                submitted: false
            });             
            }   
                
        else
        {
            this.setState({
                labelerrormessage:null,
                submitted: true
            });
           
}
}


render() {
    const { submitted, startDT, endDT,subscriptionId,orgId,selectedReport } = this.state;
    let ReportTable;
   
   
    const getNewReportButton =
        <button className="Reports-getreports-button" onClick={this.handleSubmit}>Submit</button>;
        

      

       if (submitted)
       {
           
        ReportTable = <DisplayReports startDT={startDT} endDT={endDT} subscriptionId={subscriptionId} 
           selectedReport={selectedReport}  orgId={orgId} />
       }
    
        return (<div>                 
           
            <div className="Reports">
            <div className="Reports-dvtitle">
                    Delivered Events</div>
                <div className="Reports-formgroup">
                 
                    <span className="Reports-startDT"> &nbsp;&nbsp;&nbsp;&nbsp;   
                        
                    From: <DateTimePicker showLeadingZeros={true}  calendarType="US"  
                     value={(startDT === null) ? "" : startDT} onChange={this.handleChangeStart}  />
                    </span>


                    <span className="Reports-endDT">  
                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;    &nbsp;&nbsp;&nbsp;&nbsp;   
                    To: <DateTimePicker showLeadingZeros={true}
                    calendarType="US" value={endDT} 
                    onChange={this.handleChangeEnd}    
                    minDate={startDT}  
                    maxDate={addDays(startDT, 7)} />
                    </span>       
                    {getNewReportButton}


                 

                    <br></br>
                    <br></br>
                    </div>
                   <div className="Reports-errormessage"> {this.state.labelerrormessage}</div>
                
                <div>
               {ReportTable}
               </div>
            
        </div>
        
        </div>);       
}
}

export default DeliveredEvents;