import axios from "axios";
import configData from "./../../../config.json";

const baseUri = configData.APIURL;
const apikey = configData.APIKEY;


export default async (subscriber) => {
    
    try {
        const result = await axios({
            method: 'post',
            url: baseUri + `/subscribers`,
            data: subscriber,
            headers: {
               'Ocp-Apim-Subscription-Key': apikey,
               'Content-Type': 'application/json',                              
                'Access-Control-Allow-Origin' : '*' 
            }            
        });
        
        return result.data.subscriberID;
    } catch (error) {
        return null;
    }
}