import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Moment from "moment";
import { Link,withRouter } from 'react-router-dom';
import '../../sass/reports.scss';

class ReportsTable extends Component {

    colFormatterManage = (cell) => {
        Moment.locale('en');        
        return (            
             Moment(cell).local().format('MM/DD/YYYY HH:mm:ss') 
        )
    }

    colFormatter = (cell, row) => {            
        return (                                                  
             
             <Link to={{
                pathname: '/ClientData',
                state: {
                    eventID: row.eventID,
                    orgId: this.props.orgId               
                }
            }}>
                {cell}
            </Link>
        )
    }  

    render() {

        const pagingoptions = {
            page: 1,             
            sizePerPage: 10, 
            pageStartIndex: 1, 
            paginationSize: 3,             
            hideSizePerPage: true, 
            alwaysShowAllBtns: true, 
            withFirstAndLast: true 
          };

        return (                      
            <div className="Reports-table">
                <BootstrapTable data={this.props.data} pagination options={pagingoptions}>
                    <TableHeaderColumn isKey dataField='row' width={'3%'}>
                        ROW
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='eventType' width={'18%'}>
                        EVENT TYPE
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='eventDT' dataFormat={this.colFormatterManage} width={'9%'}>
                        EVENT DATE
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='deliveredDT' dataFormat={this.colFormatterManage} width={'9%'}>
                        DELIVERED DATE
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='sinkType' width={'5%'}>
                        SINK TYPE
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='dataReport' hidden="true" dataFormat={this.colFormatter} width={'12%'}>
                        DATA
                    </TableHeaderColumn>
                </BootstrapTable>                               
            </div>
        );
    }
}

export default withRouter(ReportsTable);