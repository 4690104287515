import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import GetSubscriptionsData from '../Services/GetSubscriptionTableData.js';
import SubscriptionsTable from './SubscriptionsTable.js';
import '../../../sass/header.scss';
import '../../../sass/currentsubscriptions.scss';
import  AuthContext  from " ../../../src/Auth";

class DisplaySubscriptions extends Component {
    constructor(props) {
        super(props);
        
        if(this.props.location.state == null && localStorage.orgId == null){
            this.logOut(); 
        }
        else if(localStorage.orgId == null){
            localStorage.orgId = this.props.location.state.orgId;
            localStorage.loginSubscriberId = this.props.location.state.loginSubscriberId;
        }
        
        this.state = {
            orgId: localStorage.orgId, //this.props.location.state.orgId,
            loginSubscriberId: localStorage.loginSubscriberId, //this.props.location.state.loginSubscriberId,
            subscriptions: [],
            noSubscriptions: false,
            isMainPageLoaded: false,
            submitted: false
        };       
        this.handleAddNew = this.handleAddNew.bind(this);
        this.handleMSGAdmin = this.handleMSGAdmin.bind(this);
    }

    handleAddNew = s => {
        s.preventDefault();
        this.props.history.push({
            pathname: "/AddSubscription",
            state: {
                orgId: this.state.orgId,
                loginSubscriberId:this.state.loginSubscriberId
            }
        });
    }

    handleMSGAdmin = s => {
        s.preventDefault();
        this.props.history.push({
            pathname: "/AdminPage",
            state: {
                orgId: this.state.orgId,
                loginSubscriberId:this.state.loginSubscriberId
            }
        });
    }

    async componentDidMount() {
        const subscriptionsObject = await GetSubscriptionsData(this.state.orgId);
        if (subscriptionsObject != null) {
            this.setState({
                subscriptions: subscriptionsObject,
                isMainPageLoaded: true
            })
        } else {
            this.setState({
                noSubscriptions: true
            })
        }
    }
    logOut() {
        AuthContext.logOut();
        AuthContext.clearCache();
    }
    

    render() {
        const { orgId, subscriptions,loginSubscriberId, isMainPageLoaded, noSubscriptions } = this.state;

        const addButton =
        <button className="Addnew-button"  onClick={this.handleAddNew}>Add New</button>;

        const adminPageButton = 
        <button className="btn btn-link" onClick={this.handleMSGAdmin}>MSG Admin Panel</button>
            
        const logOut =
        <button type="button" className="btn btn-link" onClick={this.logOut}>Log Out </button>
            
        const header = 
        <div className="Header">
            <div className="Header-banner">
                <div className="Header-corner_img"></div>
            </div>
            <div className="Header-title">
                Event Bus Broker
                </div>
            <p className="Header-welcome">
                Hello {AuthContext.getCachedUser().profile.name}.
                </p>
            <div className="Header-about">
                <Link to={{
                        pathname: '/About',
                        state: {    orgId: 1 }}}
                    target="_About" rel='noopener noreferrer'>
                About
                </Link>
                <br></br><br></br><br></br>
                {logOut}<br></br>
            </div>
        </div>

        const adminHeader = 
        <div className="Header">
            <div className="Header-banner">
                <div className="Header-corner_img"></div>
            </div>
            <div className="Header-title">
                Event Bus Broker
            </div>
            <p className="Header-welcome">
                Hello {AuthContext.getCachedUser().profile.name}.
                </p>
            <div className="Header-adminabout">
                <Link to={{
                        pathname: '/About',
                        state: {    orgId: 1 }}}
                    target="_About" rel='noopener noreferrer'>
                About
                </Link>
                <br></br><br></br><br></br>
                {adminPageButton}
                {logOut}<br></br>
            </div>
        </div>
      
        if (isMainPageLoaded) {
            if (orgId === "1") {
                return (
                <div>{adminHeader}
                    <div className="CurrentSubscriptions">
                        <div className="CurrentSubscriptions-title">
                            Current Subscriptions
                        </div>
                        <div className="Page-rightcontent">
                            {addButton}
                        </div>
                        <SubscriptionsTable data={subscriptions} orgId={orgId} loginSubscriberId={loginSubscriberId} />
                        
                    </div>
                </div>
                );
            } else {
                return (<div>{header}
                    <div className="CurrentSubscriptions">
                        <div className="CurrentSubscriptions-title">
                            Current Subscriptions
                        </div>
                        <div className="Page-rightcontent">
                            {addButton}
                        </div>
                        <SubscriptionsTable data={subscriptions} orgId={orgId} loginSubscriberId={loginSubscriberId} />
                        
                    </div>
                </div>);
            }
        } else if (noSubscriptions) {
            return (<div>{header}
                <div className="CurrentSubscriptions">
                    <div className="CurrentSubscriptions-title">
                        Current Subscriptions
                    </div>
                    <SubscriptionsTable />
                    <div className="Page-rightcontent">
                        {addButton}
                    </div>
                </div>
            </div>);
        } else {
            return (<div>{header}
                <div className="Header-loading">
                <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} timeout={3000}/>        
                </div>
            </div>);
        }
    }
}

export default DisplaySubscriptions;