import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {  Input } from 'reactstrap';
import Loader from 'react-loader-spinner';
import GetSubscription from '../Services/GetSubscription.js';
import GetEventCategory from '../Services/GetEventCategory.js';
import GetSubscriber from '../Services/GetSubscriberByID.js';
import GetSubscriberData from '../Services/GetSubscriberbyUserID';
import GetSinkData from '../Services/GetSinksBySubscription.js';
import GetOrganizations from '../../GlobalServices/GetOrganizationsbyID.js';
import UpdateApproval from '../Services/UpdateApproval.js';
import GetSubscriptions from '../Services/GetSubscriptionByApprover.js';
import '../../../sass/authorize.scss';
import '../../../sass/header.scss';
import  AuthContext  from " ../../../src/Auth";


class Authorize extends Component{
    constructor(props) {
        super(props);
        this.state = {            
            subscriptionId:null,
            existSubscription:null,
            userid:AuthContext.getCachedUser().userName,
            subscriptions:[],    
            subscriberId:null,        
            eventXmlSchema:null,
            checkAPI:false,
            checkQueue:false,
            checkMail:false                    
        }; 
    }

    GetSubscriptionDetails = async () => 
    {
        var subscriptionId = this.state.subscriptionId;

        this.setState({
          checkAPI:false,
          checkQueue:false,
          checkMail:false,
          })
        
        if (subscriptionId != null) {
            const subscription = await GetSubscription(subscriptionId);    
            const subscriberObject = await GetSubscriber(subscription.subscriberID);
            const eventCategory = await GetEventCategory(subscription.eventCategoryID);            
            const organizationsObject = await GetOrganizations(subscription.orgID);

            this.setState({                                
              purpose: subscription.purpose,                                                
              approved:subscription.approved,
              topicsOfInterest: subscription.topicsOfInterest,                
              subscriberName:subscriberObject.name,
              eventname: eventCategory.eventName,                
              organizationname:organizationsObject.description,
              eventXmlSchema: eventCategory.eventXmlSchema,
              subscriptionName:subscription.subscriptionName,
              contactEmail:subscription.contactEmail 
          });          
            var sinks = await GetSinkData(subscriptionId);
            
            if (sinks != null) {                                      
              for (var i = 0; i < sinks.length; i++) {
                var sink = sinks[i];                  
                    if (sink.sinkType === 'C') {
                      this.setState({
                        checkAPI:true})
                    }
                    if (sink.sinkType === 'Q') {
                      this.setState({
                        checkQueue:true})
                    }
                    if (sink.sinkType === 'E') {
                      this.setState({
                        checkMail:true})
                    }
              }
            }
        }       
    }

     refreshPage () {
      window.location.reload(false);
    }

    handleSubmit = async(e) => {        
      var subscriptionId = this.state.subscriptionId;      
      var subscriberId=this.state.subscriberId;        
      UpdateApproval(subscriberId,subscriptionId,e); 
      if(subscriberId > 0)
      {
        alert("Notified to Subscriber");        
      }
      this.refreshPage();
    }  

    handleChangeSubscription = async e => {
      this.state.subscriptionId = e.target.value;
      this.GetSubscriptionDetails();   
  }
  
    GetDefaultSubscriptionDetails = async () => {
      const subscriptionsObject = await GetSubscriptions(this.state.userid);               
      if (subscriptionsObject != null) {
        this.setState({    
          subscriptions:subscriptionsObject,
          subscriptionId:subscriptionsObject[0].subscriptionID,          
          existSubscription:true
        })
      const subscriberObject = await GetSubscriberData(this.state.userid);
      if (subscriberObject != null) {
        this.setState({            
            subscriberId:subscriberObject.subscriberID            
        })  
      }
        this.GetSubscriptionDetails();
        
        }
        else
        {
          this.setState({ 
            existSubscription:false
          })          
        }  
    }


    async componentDidMount()  {
      this.GetDefaultSubscriptionDetails();
             
    }
   
    logOut() {
      AuthContext.logOut();
      AuthContext.clearCache();
  }

  
  render() {
    const reactStringReplace = require('react-string-replace')
    const { approved,subscriberName, existSubscription,purpose, organizationname, topicsOfInterest, eventname, subscriptionId,
    eventXmlSchema,subscriptions,contactEmail } = this.state; 
    const xmldata=reactStringReplace(reactStringReplace(eventXmlSchema, /(1#)/g, () => ""),/(0#)/g, () => "")        
    let subscriptionType;

        const logOut =
        <button type="button" class="btn btn-link" onClick={this.logOut}>Log Out </button>
             
        const header = <div className="Header">
        <div className="Header-banner">
            <div className="Header-corner_img"></div>
        </div>
        <div className="Header-title">
            Event Bus Broker
            </div>
        <p className="Header-welcome">
            Hello {AuthContext.getCachedUser().profile.name}.
            </p>
        <div className="Header-about">
        <Link to={{
                pathname: '/About',
                state: {    orgId: 1 }}}
            target="_About" rel='noopener noreferrer'>
           About
        </Link>
         <br></br><br></br><br></br> 
        {logOut}<br></br>
                 </div>
                 
                 </div>
   
      if(existSubscription)      
      { 
        if(subscriptions.length > 1)
        {
          subscriptionType= <Input type="select" id="Authorizecategory" value={subscriptionId} onChange={this.handleChangeSubscription}>
                          {subscriptions.map((subscriptiondetail) => (<option value={subscriptiondetail.subscriptionID}>
                            {subscriptiondetail.subscriptionName}</option>))}</Input>
        }

        else if(subscriptions.length === 1)
        {
          subscriptionType= <label>{subscriptions[0].subscriptionName}</label>

        }


        return(
        <div className="App">
                {header}       
                  
          <header className="App-header2">
          
          <p> Authorisations for subscription</p>
          </header>
        
      

          <div className="App1">
          <header className="App-header1">
          <p> Please review the following subscription request and either Approve or Deny /Un -Approve it.</p>

            
          </header>
        </div>
        <div class="tab">
    
        <div class="container6">
        <div><label>Subscription Name:</label></div>
        <div class="container7">
        {subscriptionType}
        </div>
        <div class="buttondiv">
        <button class="tablinks1" onClick={() => this.handleSubmit('1')}>Approve</button> 
        <button class="tablinks2" onClick={() => this.handleSubmit('0')}>Deny</button>
          </div>
        </div>
        </div>


  
          <div class="subscriptiondetails">
        <div className="App-header3">
        <label >Subscription Details</label>
          </div>
          <div class="container1">

          <label >Current status:</label>
          <label >{(approved===0)?"Un-Approved":"Approved"}</label></div>
        
        <div class="container1">
        <label >Employee:</label>
        <label >{subscriberName}</label>
        </div>
        
        <div class="container1">
        <label >Email Address:</label>
        <label >{contactEmail}</label>
        </div>
        <div class="container1">
        <label >Organisation:</label>  
        <label >{organizationname}</label>
        </div>
        <div class="container1">
        <label >Purpose:</label>
        <label >{purpose}</label>
        </div>
        <div class="container1">
        <label >Event category:</label>
        <label >{eventname}</label>
        </div>
        <div class="container1">
        <label>Events:</label>
        <label >{topicsOfInterest}</label>
        </div>
        <div class="container1">
        <label >Delivery:</label>
        <label ><input type="radio" name="A" id="API"   checked={this.state.checkAPI === true} />API&nbsp;&nbsp;&nbsp;&nbsp;
        <input type="radio" name="A" id="Queue"  checked={this.state.checkQueue === true} />Queue&nbsp;&nbsp;&nbsp;&nbsp;
        <input type="radio" name="A" id="Email"  checked={this.state.checkMail === true} />Email</label>
      
      </div>
      
          </div>

    
  
  <div class="subscriptiondetails"  >    
  <div className="App-header3"><label >Payload Requested</label></div>    
  <div class="container4">      
    <pre className="txtstyle">{xmldata}</pre>                                
     </div>  
  </div>

  <footer className="col3"></footer>
    </div>
  );
  }

        else if(existSubscription===false)
        {
          return (<div>{header}
            <div className="Header-loading">
            No Subscription is pending under your queue.
            </div>
          </div>);
        }
        else {
          return (<div>
              <div className="Header-loading">
              <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} timeout={3000}/>  
              </div>
          </div>);
      }
        
}
}

export default Authorize;

