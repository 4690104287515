import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import Moment from "moment";
import '../../../sass/viewchanges.scss';

class SubscriptionChangesTable extends Component {
    colFormatterManage = (cell) => {
        Moment.locale('en');        
        return (            
             Moment(cell).local().format('MM/DD/YYYY HH:mm:ss') 
        )
    }

    render() {

        const pagingoptions = {
            page: 1,             
            sizePerPage: 3, 
            pageStartIndex: 1, 
            paginationSize: 3,               
            hideSizePerPage: true, 
            alwaysShowAllBtns: true, 
            withFirstAndLast: true 
          };
        
        return (            
            <div className="ViewChanges-table">
                <BootstrapTable data={this.props.data} pagination options={pagingoptions}>                
                    <TableHeaderColumn isKey dataField='subscriberName' headerAlign='center' width={'14%'}>
                        USER
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='insertDateTime' headerAlign='center'  dataFormat={this.colFormatterManage} width={'18%'}>
                        DATE
                    </TableHeaderColumn>
                    <TableHeaderColumn columnClassName='ViewChanges-rowstyle' headerAlign='center' dataField='whatChanged' width={'43%'}>
                        CHANGES
                    </TableHeaderColumn>                                    
                </BootstrapTable>
            </div>
        );
    }
}

export default SubscriptionChangesTable;