import axios from "axios";
import configData from "./../../../config.json";

const baseUri = configData.APIURL;
const apikey = configData.APIKEY;



export default async (orgId) => {
    try {
        const result = await axios({
           method: "get",            
            url: baseUri + `/organizations/${orgId}/subscriptiondetails`,
            headers: { 'Ocp-Apim-Subscription-Key': apikey,
                        'Access-Control-Allow-Origin' : '*'
                     }
        });
        return result.data.subscriptions;
    } catch(error) {
        return null;
    }
}
