import React, { Component } from "react";
import Loader from 'react-loader-spinner';
import GetClientData from './Services/GetClientData.js';
import GetSinkData from './Services/GetSinkData.js';
import ReportsTable from './ReportsTable.js';
import SubscriptionReportTable from './SubscriptionReportTable'
import moment from "moment";
import '../../sass/reports.scss';
import '../../sass/header.scss';

class DisplayReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientData: [],
            loaded: false,
            error: false,
            clientReport:false,
            SubscriptionReport:false,
            orgId:this.props.orgId
        };
    }

    async componentDidMount() {
        var startTime = moment(this.props.startDT).format('YYYY-MM-DDTHH:mm:ss');
        var endTime = moment(this.props.endDT).format('YYYY-MM-DDTHH:mm:ss');
        var subscriptionId = this.props.subscriptionId;        
        var subscriptionName=this.props.subscriptionName;
        var selectedReport=this.props.selectedReport;
        if (selectedReport === 'events')
        {
            var clientDataObject = await GetClientData(startTime, endTime, subscriptionId);
            if (clientDataObject != null) {                  
                this.setState({
                    clientData: clientDataObject,
                    clientReport:true,
                    loaded: true
                });
            } else {
                this.setState({
                    error: true
                })
            }
        }
        else
        {
            var subscriptionDataObject = await GetSinkData(subscriptionId,subscriptionName);
            if (subscriptionDataObject != null) {                 
                this.setState({
                    subscriptionData: subscriptionDataObject,
                    SubscriptionReport:true,
                    loaded: true
                });
            } else {
                this.setState({
                    error: true
                })
            }
        }
    }        

    render() {
        const { clientData,subscriptionData, loaded, error,clientReport,SubscriptionReport,orgId } = this.state;

       
        if (error) {
            return (<div className="Reports-error_msg">
                No Data Available for the Selected Range
            </div>);
        }
        else if (loaded && clientReport) {
            return (<div> 
                <ReportsTable data={clientData} orgId={orgId}  />
            </div>);
        } else if (loaded && SubscriptionReport) {             
            return (<div>
                <div className="Reports-subscriptiontitle">
                        Subscription Details
                    </div>
                    <div className="Reports-table2">
                <SubscriptionReportTable data={subscriptionData} />
                </div>
            </div>);
        } else {
            return (<div>
                <div className="Header-loading">
                <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} timeout={3000}/>
                </div>
            </div>);
        }
    }
}

export default DisplayReports;