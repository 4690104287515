import axios from "axios";
import configData from "./../../../config.json";

const baseUri = configData.APIURL;
const apikey = configData.APIKEY;


export default async (subscriptionId) => {
    try {        
        const result = await axios({
           method: "get",
            headers: { 'Ocp-Apim-Subscription-Key': apikey,
                       'Access-Control-Allow-Origin' : '*' },
            url: baseUri + `/subscriptions/${subscriptionId}/sinks`
        });                
        return result.data.sinks;
    } catch (error) {
        return null;
    }
}