
import axios from "axios";
import configData from "./../../../config.json";

const baseUri = configData.APIURL;
const apikey = configData.APIKEY;



export default async (subscriberId,subscriptionId,isApproved) => {    
    try {
        await axios({
            method: 'put',
            url: baseUri + `/authorize/${subscriptionId}?subscriberID=${subscriberId}&approvalStatus=${isApproved}`,                        
            headers: {
               'Content-Type': 'application/json',
               'Ocp-Apim-Subscription-Key': apikey,
                'Access-Control-Allow-Origin' : '*' 
            }
            
        });
        return subscriptionId;        
    } catch (error) {
        return;
    }
}

