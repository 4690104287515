import React,{Component} from "react";
import { Link } from 'react-router-dom';
import ESBBrokerOptions from '../../../img/ESBBrokerOptions.png';
import '../../../sass/about.scss';
import '../../../sass/header.scss';
import  AuthContext  from " ../../../src/Auth";


class AboutPage extends Component{
    constructor(props) {
    super(props);
    this.state = {
        orgId: 1
    };}
    logOut() {
        AuthContext.logOut();
        AuthContext.clearCache();
    }
    render() {
        const logOut =
    <button type="button" class="btn btn-link" onClick={this.logOut}>Log Out </button>
        const header = <div className="Header">
            <div className="Header-banner">
                <div className="Header-corner_img"></div></div>
            <div className="Header-title">
                Event Bus Broker
            </div>          

    <p className="Header-welcome">
    Hello {AuthContext.getCachedUser().profile.name}.
    </p>
            <div className="Header-about"> <Link to={{
                    pathname: '/About',
                    state: {    orgId: 1 }}}
                target="_About" rel='noopener noreferrer'>
          
            </Link> &nbsp;&nbsp;&nbsp;&nbsp;  <br></br><br></br><br></br>  {logOut}
      
                    </div>

                  </div>;

        const headerlink= <div className="AboutPage-headerlink">
        <Link to={{
         pathname: '/Subscriptions',
         state: {    orgId: this.state.orgId }
         }}>Subscriptions </Link> {">"} About  </div>;

         const aboutcontent=
            <div className="content">
            <h1 className="heading"> About the Events Bus Broker </h1>

            <img src={ESBBrokerOptions} alt="EBB"/>

            <div className="Styleproperty1">
                Click on the &nbsp;
                 <a target="_blank" href='https://alaskaair.sharepoint.com/:w:/r/sites/dap/Shared%20Documents/Events%20Bus%20Broker%20(EBB)/EBB-UsersManual.docx?d=w9e96578071ec4eef91a3ec7d17bc2b4c&csf=1&web=1&e=haWsqa'>
                     Link</a> for User Manual</div><br></br>

            <div className="Styleproperty1">
                Terminology</div>
                <div className="Styleproperty2">
            <u>Subscriber</u>: A person that registers through this Portal, and is able to create Subscriptions.</div>
            <div className="Styleproperty2"><u>Organization</u>: Grouping of developers that belong to the same Dev team.</div>
            <div className="Styleproperty2"><u>Subscription</u>: A request to receive events from a particular category, during a specific period of time, and delivered</div>
            <div className="Styleproperty4">through a specific mechanism.</div>
            <div className="Styleproperty2"><u>Sink</u>: The mechanism through which events will be delivered.</div>
            <div className="Styleproperty2"><u>Event Category</u>: Family of events. For example, "Flight Ops" category includes events like ETA/ETD changes, and<br></br>
                flight swaps. Whereas "Baggage" category includes events for changes to bag tags through BSM and BPM messages.</div>
            
            <div className="Styleproperty1">Overview</div>
            <div className="Styleproperty2">Subscribers belong to Organizations, and all Subscriptions are visible and editable by all members of the same Organization.
            <br></br> This is so that Subscriptions remain under the Organization's control, rather the individuals, and active members can still 
            <br></br> support them even when their creators move to other Orgs. 
            <br></br>  Switching to a different Organization is possible, just email your request to icc.support@alaskaair.com to make the change.</div>

            <br></br>
            <div className="Styleproperty2"> Events delivered through Subscriptions are of one Event Category at a time. To receive events from two or more categories, 
            <br></br>different subscriptions must be created.</div>
            <br></br>
            <div className="Styleproperty2">Each subscription can deliver all events chosen to up to 3 mechanisms at the same time: Via web service and API calls, via<br></br>
            messages on MQSeries QMMF* queue managers, and via emails.</div>
            <br></br>
            <div className="Styleproperty2">All events are delivered in XML format. Upon choosing an Event Category, click the Get Schema button to see the schema
            <br></br>used for that Event Category, and see an example of the message.</div>
            <br>
            </br>
            <div className="Styleproperty2">Each sink has the following independent options:<br></br> - Start and End feed times: Only events that were generated within that window of time will be delivered.<br></br> - Retry limit: Number of tries that ESB Broker will attempt to deliver the event to the sink indicated.<br></br>
            - Retry wait: Number of seconds that ESB Broker will wait between retries.<br></br>
            - Message age: Number of seconds before undelivered messages to active and inactive sinks will expire.<br></br>

            <div className="Styleproperty2"><u>Example</u>: Subscription for Flight Ops ETA and ETD events for an API sink with the following delivery settings:</div>
            <div className="Styleproperty3">API URL: https://api.alaskaair.com/mysystem/1/myflightapi</div>
            <div className="Styleproperty3">Start feed: 10/1/16 00:00 AM</div>
            <div className="Styleproperty3">End feed: 12/31/16 11:59 PM</div>
            <div className="Styleproperty3">Retry limit: 5</div>
            <div className="Styleproperty3">Retry wait: 15</div>
            <div className="Styleproperty3">Message Age: 60</div><br>
            </br>
            <div className="Styleproperty3">Expected behavior: All ETA and ETD events generated between 10/1/16 00:00 AM and 12/31/16 11:59 PM will be delievered to </div>
            <div className="Styleproperty3">https://api.alaskaair.com/mysystem/1/myflightapi as they occur from Flight Ops system. In the event that "myflightapi" is down, </div>
            <div className="Styleproperty3">ESB Broker will wait 15 seconds before trying to deliver them again, and it will retry 3 more times, waiting 15 seconds between </div>
            <div className="Styleproperty3">retries. Since 5 * 15 equals 75, and the Message Age is set to 60, ESB Broker will not attempt to deliver it the last 5th time, </div>

            <div className="Styleproperty3">because the message will have expired by then due to the message expiration setting.</div><br>
            </br>
            <div className="Styleproperty3">Sinks are registered as soon as their endpoint (URL for APIs, Queue name or email address) are provided, but the events will</div>
            <div className="Styleproperty3">be delivered only within the time window specified.To remove a sink from a subscription, click on the "Delete Sink" icon inside </div>  
            <div className="Styleproperty3">each sink's tab, and Save the subscription. </div><br>
            </br>
            <div className="Styleproperty3">The Reports tab provides a list of all the events delivered to all sinks for active subscriptions, based on a date-time range.</div>
            <div className="Styleproperty3">However, if the subscription, or the sinks are removed, all events delivered will be removed from the database as well.</div>
            <div className="Styleproperty3"> In that case, you can use Sumo Logic to find events that were sent to sinks after they have been removed. Just type the following</div>
            <div className="Styleproperty3"> search criteria: _sourceCategory=Tibco_BW_Audit_TEST EventBrokerEDA</div>
                    

            </div>
                    </div>
           
     
            return(
            <React.Fragment>
                <div>{header}</div>
                <div>
                        <div>{headerlink} {aboutcontent}</div>
                        </div>

            </React.Fragment>
            
                );
                
                }
            } 
            export default AboutPage;