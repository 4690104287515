export default (props) => {
    var isPropChanged = null;
    if (props[0] === null || props[0] === '') {
        isPropChanged = false;
    } else {
        isPropChanged = true;
    }
    for (var i = 1; i < props.length; i++) {
        if (props[i] === null || props[i] === "") {
            if (isPropChanged) {
                return "ERROR";
            } else {
                isPropChanged = isPropChanged && false;
            }
        } else {
            isPropChanged = isPropChanged && true;
        }
    }
    return isPropChanged;
}
