import axios from "axios";
import configData from "./../../../config.json";

const baseUri = configData.APIURL;
const apikey = configData.APIKEY;


export default async (sink) => {
    try {
        const result = await axios({
            method: 'post',
            url: baseUri + `/sinks`,
            data: sink,
            headers: {
               'Content-Type': 'application/json',
               'Ocp-Apim-Subscription-Key': apikey,
                'Access-Control-Allow-Origin' : '*' 
            }
        });
        return result.data.sinkID;
    } catch (error) {
        return null;
    }
}