export default (props) => {    
    if (props[0] === null || props[0] === '') {
        return "Subscription Name is required";
    } else if (props[1] === null || props[1] === '') {
        return "Purpose is required";        
    }   
    else if (props[3] === null || props[3] === '') {        
        return "Category is required";
    }
    else if (props[4] === null || props[4] === '') {        
        return "Event is required";
    }
    else if (props[2] === null || props[2] === '') {
        return "Contact email is required"        
    }
    else
        return true; 
}
    